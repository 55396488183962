import './6.scss';

import React from 'react';
import LikeButton from '../likebutton.js';
import Image from '../image.js';

import {ReactComponent as Template6Like} from '../img/template_6_like_thumb.svg';
import {ReactComponent as CloseButton} from '../img/close6.svg';

class Template extends React.Component {
	render() {
		const settings = this.props.settings;
		const fbPage = this.props.fbPage;

		return (
			<React.Fragment>
				<button id="close" onClick={this.props.onClose} aria-label="Close">
					<CloseButton />
				</button>

				<div id="image-box">
					<Template6Like />
				</div>
				<div id="content">
					<h2>{settings.title}</h2>
					<h3>{settings.subtitle}</h3>
				</div>
				<div id="footer">
					<div id="like-with-details" className="center-aligned">
						<LikeButton pageId={fbPage.id} key={fbPage.id} />
						<div className="details">{fbPage.prompt}</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Template;
