import mixpanel from 'mixpanel-browser';

export default class AppContextMgr {
	constructor(defaultSettings) {
		this.init = this.init.bind(this);
		this.initInffuse = this.initInffuse.bind(this);
		this.initData = this.initData.bind(this);

		this.login = this.login.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		this.onLogin = this.onLogin.bind(this);
		this.loadSites = this.loadSites.bind(this);
		this.loadProject = this.loadProject.bind(this);
		this.sites = this.sites.bind(this);
		this.isDirty = this.isDirty.bind(this);
	
		this.state = {};
		this.data = {};
		this.isDataReady = false;
		this.onChangeListeners = [];

		this.showPreview = false;

		this.defaultSettings = defaultSettings;
	}

	/*----------------*/

	onChange(callback) {
		this.onChangeListeners.push(callback);
	}

	broadcastDataChange() {
		this.onChangeListeners.forEach((callback) => callback());
	}

	/*----------------*/

	ready() {
		return !!this.inffuse;
	}

	dataReady() {
		return this.isDataReady;
	}

	/*----------------*/

	init(app_id,onDataChanged) {
		mixpanel.init('5dd010ef066795bdc97068c9b3873e2f');

		this.onDataChanged = onDataChanged;
	
		this.initInffuse(app_id, () => {
			this.initData(onDataChanged);
		});
	}
	
	initInffuse(app_id,callback) {
		let onInffuseLoaded = (Inffuse) => {
			this.inffuse = Inffuse;
			window.inffuse = Inffuse;

			if (!this.loggedIn()) {
				this.isDataReady = true;
				mixpanel.identify(this.inffuse.user.id());
			}

			callback && callback();
		}

		var server = '//platform.likepopup.com';
		if (document.location.host.startsWith("localhost")) {
			server = 'http://localhost:10099';
		}
		
		let inffuseSDK = new window.InffuseSDK_01(window.jQuery.ajax, app_id);
		inffuseSDK.server = server;
		inffuseSDK.context_params = {
			platform: 'web'
		};

		inffuseSDK.init(onInffuseLoaded);
	}

	initData(callback) {
		return this.loadSites(callback);
	}
	
	/*----------------*/

	loggedIn() {
		return this.inffuse && this.inffuse.user.id();
	}

	onLogin() {
		mixpanel.identify(this.inffuse.user.id());
		mixpanel.people.set({ 
			id: this.inffuse.user.id(),
			name: this.inffuse.user.name(),
			plan: this.inffuse.user.plan() || '(free)'
		});
	}

	login(email,password) {
		return new Promise((resolve, reject) => {
			this.inffuse.user.login(email,password)
				.then((_result) => {
					this.initData();
					this.onLogin();			
					this.track('Logged In',{email});

					resolve();
				})
				.fail((result) => {
					reject(result.responseJSON?.error);
				})
		});
	}

	signup(name,email,password) {
		return new Promise((resolve, reject) => {
			this.inffuse.user.create(name,email,password)
				.then((_result) => {
					this.initData();
					this.onLogin();
					this.track('Signed Up',{name,email});
					resolve();
				})
				.fail((result) => {
					reject(result.responseJSON?.error);
				})
		});
	}

	logout() {
		return new Promise((resolve, reject) => {
			this.inffuse.user.logout()
				.then((_result) => {
					this.track('Logged Out');
					mixpanel.reset();

					document.location.reload();
					resolve();
				})
				.fail((result) => {
					reject(result.responseJSON?.error);
				})
		});
	}

	sendPasswordReset(email) {
		this.track('Reset Password Requested',{email});

		return new Promise((resolve, reject) => {
			this.inffuse.user.resetPasswordRequest(email)
				.done(function(_response){
					resolve();
				})
				.fail(function(result){
					reject(result.responseJSON?.error);
				});
		});
	}

	resetPassword(user_id,password,token) {
		this.track('Reset Password Completed',{user_id});

		return new Promise((resolve, reject) => {
			this.inffuse.user.resetPassword(user_id,password,token)
				.done(function(_response){
					resolve();
				})
				.fail(function(result){
					reject(result.responseJSON?.error);
				});
		});
	}

	/*----------------*/

	user() {
		return this.inffuse.user;
	}

	plan() {
		return this.inffuse.user.plan();
	}
	
	isFreeTier() {
		return this.inffuse.user.free();
	}

	sites() {
		if (this.data.sites === undefined) {
			// await load
		}

		return this.data.sites;
	}

	/*----------------*/

	loadSites(callback) {
		if (!this.ready()) {
			return;
		}

		var params = {
			user: this.inffuse.user.id(),
		};

		this.inffuse.requestAPI('sites', params, 'GET')
			.done((data) => {
				var sitesById = {};
				data.sites.forEach((site) => {
					site.projects = [];
					sitesById[site.id] = site;
				});

				this.inffuse.requestAPI('projects',{user: this.inffuse.user.id()})
					.then((response) => {
						response.projects.forEach((project) => {
							if (!sitesById[project.site_id])
								return;

							sitesById[project.site_id].projects.push(project);
						});
					})
					.always(() => {
						this.data.sites = data.sites;
						
						this.isDataReady = true;
						this.broadcastDataChange();
						callback && callback();
					})
			})
			.fail(() => {
				console.log("Sites list error");
				this.isDataReady = true;
				callback && callback();
			});
	}

	loadProject(id,callback) {
		if (!this.ready()) {
			return;
		}

		this.inffuse.projects.load(id)
			.done(callback);
	}

	deleteProject(id,callback) {
		var params = {
			user: this.inffuse.user.id(),
		};

		return this.inffuse.requestAPI('projects/'+id, params,'DELETE')
			.done((data) => this.loadSites(callback));
	}

	/*----------------*/
	
	isDirty(flag) {
		if (flag !== undefined) {
			this.dirtyFlag = flag;
			this.broadcastDataChange();
			
		} else {
			return this.dirtyFlag;
		}
	}

	/*----------------*/
	
	track(eventType,eventParams) {
		mixpanel.track(eventType,eventParams);
	}

	/*----------------*/

	redirect(history,path) {
		history.push(path);
	}

	/*----------------*/

	onLocationChanged(location) {
	}

	/*----------------*/

	onHeaderContentChange(callback) {
		this.headerContentChangeCallback = callback;
	}

	setCustomHeaderContent(content) {
		this.headerContentChangeCallback(content);
	}
}

