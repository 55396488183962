import React from 'react';
import { withRouter } from 'react-router-dom';

import BaseView from 'pages/base.js';
import {Button,Form,Grid,Heading,Input,Pretitle,Select,Spinner,Stack,Text} from 'designSystem';
import FbButton from 'pages/fbButton.js';

/*---------------------------------------------*/

const FB_SCRIPT_ID = 'popup-facebook-jssdk';

/*---------------------------------------------*/

class PageSettings extends BaseView {
	constructor() {
		super();

		this.state = {
			loading: true
		}

		this.connect = this.connect.bind(this);
		this.disconnect = this.disconnect.bind(this);
		this.loadPages = this.loadPages.bind(this);
		this.onNameChanged = this.onNameChanged.bind(this);
		this.onPageSelected = this.onPageSelected.bind(this);
		this.setPage = this.setPage.bind(this);
	}

	componentDidMount() {
		this.context.inffuse.project = this.props.project;
		this.loadPages();

		setTimeout(() => {
			const appId = '639530319449893';
			const script = document.createElement("script");
			document.body.appendChild(script); 

			script.onload = () => {
				window.FB && setTimeout(() => window.FB.XFBML.parse(document.getElementById('root')));
			}
			
			script.id = FB_SCRIPT_ID;
			script.src = `//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=${appId}&rand=${Math.random()}`;
			script.async = true;

		},100);
	}

	loadPages() {
		const setPages = (response) => {
			const pages = response.pages;
			const pagesOptions = [];
			
			if (pages.length === 0)
				pagesOptions.push({label: 'No Facebook pages found', value: '-1', className: 'disabled'});
			else {
				pages.forEach((page,_index) => pagesOptions.push({label: page.name, value: page}));
			}

			this.setState({
				loading: false,
				connected: true,
				pages: pages,
				pagesOptions: pagesOptions
			});
		}

		this.context.inffuse.services.facebook.pages("is_promotable")
			.done(setPages)
			.fail(() => this.setState({loading: false}));
	}

	connect() {
		this.context.inffuse.services.facebook.connect("pages_show_list",(data) => {
			this.setState({
				connected: true
			});
			
			this.props.project.set('user',{
				id: data.user.id,
				name: data.user.name,
				photo: `https://graph.facebook.com/${data.user.id}/picture?access_token=${data.access_token}`
			});

			this.loadPages();
		});
	}

	setPage(page) {
		debugger;
		const settings = this.props.project.get('settings') || this.context.defaultSettings;
		settings.fbpage = page === undefined ? undefined : {
			id: page.id,
			name: page.name
		}

		this.props.project.set('settings',settings);
		this.context.isDirty(true);
		this.context.broadcastDataChange();
	}

	disconnect() {
		return new Promise((resolve, reject) => {
			this.context.inffuse.services.facebook.disconnect()
				.done(() => {
					this.setPage(undefined);
					this.setState({
						connected: false,
						pages: null,
						pagesOptions: null
					});

					this.context.broadcastDataChange();

					this.props.project.setToken('facebook','page_access_token',null);

					resolve();
				})
				.fail((result) => reject(result.responseJSON?.error));
		});
	}

	onNameChanged(value) {
		this.nameInterval && clearInterval(this.nameInterval);
		this.nameInterval = setTimeout(() => this.context.inffuse.project.update({name: value}), 1000);
	}

	onPageSelected(page) {
		this.props.project.setToken('facebook','page_access_token',page.token);
		this.setPage({
			id: page.id,
			name: page.name
		});

		this.forceUpdate();
		this.context.broadcastDataChange();
	}

	render() {
		const pagesOptions = (this.state.pagesOptions || []).map((page) => <Select.Option label={page.label} value={page.value} key={page.value.id} />);
		
		let content = <Spinner size={48} />;
		if (!this.state.loading) {
			if (this.state.connected) {
				const settings = this.props.project.get('settings') || {};
				const user = this.props.project.get('user') || {};
				const pageId = settings.fbpage && settings.fbpage.id;
				const page = pageId && this.state.pages && this.state.pages.find(page => page.id === pageId);

				content = [
					<div key='action' style={{display: 'grid', gridTemplateColumns: '40px auto 16px min-content', alignItems: 'center', marginTop: 20}}>
						<img style={{width: 32, height: 32, borderRadius: 500}} src={user.photo} alt="user profile" />
						<Text>{user.name}</Text>
						<div></div>
						<Form onSubmit={this.disconnect} silent>
							<Button variant='outline' loading={Form.loading} key='disconnect'>Disconnect</Button>
						</Form>
					</div>,
					<div style={{height: 'var(--space-stack-l)'}} key='separator' />,
					<Select 
						label='Choose one of your Facebook pages'
						value={page} 
						placeholder='Choose page' 
						onChange={this.onPageSelected} 
						fullwidth
						key='dropdown'
					>
						{pagesOptions}
					</Select>
				];

			} else {
				// content = <Button variant='primary' show="!settings.fbconnected" label='Connect to Facebook' onClick={this.connect} />
				content = <FbButton onClick={this.connect} />;
				// content = <div onClick={this.connect} className="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
			}
		}

		return (
			<Grid fluid nomargin>
				<div style={{display: 'grid', gridTemplateColumns: 'auto min-content'}}>
					<div style={{width: 400}}>
						<Heading variant='heading3'>Your popup</Heading>
						<div style={{height: 'var(--space-stack-l)'}} />

						<Pretitle color='--primary-900'>Popup Details</Pretitle>
						<div style={{height: 'var(--space-stack-m)'}} />
						<Form>
							<Stack spacing="comfortable">
								<Input label='Popup name' initialValue={this.props.project.name()} onChange={this.onNameChanged} />
							</Stack>
						</Form>

						<div style={{height: 'var(--space-stack-xl)'}} />
						<div style={{height: 'var(--space-stack-m)'}} />

						<Pretitle color='--primary-900'>Facebook Page Setup</Pretitle>
						<div style={{height: 8}} />

						{content}
					</div>
					<Form silent onSubmit={this.props.onPublish} style={{textAlign: 'right'}}>
						<Button variant='primary' loading={Form.loading} disabled={!this.context.isDirty()} >Publish</Button>
					</Form>
				</div>
			</Grid>
		)
	}
}

/*---------------------------------------------*/

export default withRouter(PageSettings);
