import React from 'react';

import {
	Link as RouteLink,
	withRouter
} from "react-router-dom";

import BasePrivateView from 'pages/basePrivate.js';
import ProjectsList from './projects.js';
import {Button,Grid,Heading} from 'designSystem';
import 'index.scss';

/*---------------------------------------------*/

class SitePage extends BasePrivateView {
	constructor() {
		super();

		this.state = {
			sites: []
		}

		this.name = this.name.bind(this);
	}
	
	name() {
		if (!this.site.domain)
			return 'Untitled site';

		return this.site.domain.replace(/(^\w+:|^)\/\//, '')

	}

	renderView() {
		if (!this.site) {
			let id = this.props.match.params.id;
		
			let sites = this.context.sites();
			this.site = sites && sites.find(site => site.id === id);
			if (!this.site) {
				return null;
			}
		}

		return (
			<Grid showGrid={this.state.showGrid} fluid={this.state.fluidGrid}>
				<Heading variant='heading1' grid-start={1} grid-end={8}>{this.name()}</Heading>
				<div style={{height: 'var(--space-stack-s)'}} />
				
				<div style={{display: 'inline-block'}}>
					<RouteLink to={`/sites/${this.site.id}/new`}>
						<Button variant="primary" type='button'>Create new {this.context.cfg.project.name.toLowerCase()}</Button>
					</RouteLink>
				</div>

				<div style={{height: 32}}></div>
				<div className='content'>
					<ProjectsList site={this.site}></ProjectsList>
				</div>
				
				{/* <Checkbox label='Show grid' onChange={value => this.setState({showGrid: value})} />
				<Checkbox label='Fluid grid' onChange={value => this.setState({fluidGrid: value})} /> */}
			</Grid>
		)
	}
}

/*---------------------------------------------*/

export default withRouter(SitePage);
