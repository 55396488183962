import React from 'react';
import {Link as RouterLink,withRouter} from "react-router-dom";

import BasePrivateView from 'pages/basePrivate.js';
import ProjectsList from './site/projects.js';
import CreateModal from './create.js';
import {Button,Grid,Heading,Link,Stack,Text} from 'designSystem';
import Loader from 'admin-kit-local/components/loader';

import 'index.scss';

/*---------------------------------------------*/

class HomeView extends BasePrivateView {
	constructor() {
		super();

		this.state = {
			sites: [],
			create: false
		}

		this.onCreatePopup = this.onCreatePopup.bind(this);
	}
	
	componentDidMount() {
		this.context.onChange(() => this.forceUpdate());
	}
	
	onCreatePopup() {
		this.setState({create: true});
	}

	renderView() {
		const sites = this.context.sites();
		if (sites === undefined) {
			return <Loader />;
		}

		const cta = (
			<div grid-start={15} style={{textAlign: 'right'}}>
				<Button variant="primary" type='button' style={{verticalAlign: 'top'}} onClick={this.onCreatePopup}>Create new popup</Button>
			</div>
		);

		const emptyState = (sites && sites.length) 
			? null
			: (
				<div style={{width: 300, margin: 'auto', marginTop: 90}}>
					<Heading variant='heading3'>Hi! <span role="img" aria-label="hand icon">👋</span></Heading>
					<div style={{height: 'var(--space-stack-xl)'}} />
					<Text>
						Add an awesome Like Popup to your site. 
					</Text>
					<div style={{height: 'var(--space-stack-m)'}} />
					<Text>
						Link to your Facebook page, choose from a variety of custom designed popup templates and customize your texts to have a better fit for your audience.
					</Text>
					<div style={{height: 'var(--space-stack-xl)'}} />
					<Button variant="primary" type='button' onClick={this.onCreatePopup}>Create your first popup</Button>
				</div>
			);
		
		return (
			<Grid showGrid={this.state.showGrid} fluid>
				<div style={{height: 40}} />
				<Heading variant='heading3' color='--primary-900' grid-end={8}>My popups</Heading>
				{emptyState ? null : cta}

				<div style={{height: 'var(--space-stack-xl)'}} />
				
				<div grid-end="-1">
					{ sites && sites.map(site => { 
						const upgradePath = `/user/account/billing/pricing?site=${site.id}`;
						const billingContent = site.plan 
							? <div className="badge" style={{alignSelf: 'center'}}>Premium</div> 
							: <RouterLink to={upgradePath}><Link>Upgrade</Link></RouterLink>;
							// : <Link onClick={() => this.context.redirect(this.props.history,upgradePath)}>Upgrade</Link>;

						return [
							<div className="site" key={site.id}>
								<div style={{display: 'grid', gridTemplateColumns: 'auto min-content', alignItems: 'center'}}>
									<Heading variant='heading4'>{site.domain || 'Untitled'}</Heading>
									{billingContent}
								</div>
								<Stack.Spacer height='m' />
								<ProjectsList projects={site.projects} project_name='popup' style={{opacity: emptyState ? 0.4 : 1}} />
								<Stack.Spacer height='m' />
							</div>,
							<Stack.Spacer height='xl' key={`${site.id}--spacer`}/>
						]
					}) }
				</div>
						
				{ emptyState }

				<br/><br/>
				{/* <Checkbox label='Show grid' onChange={value => this.setState({showGrid: value})} />
				<Checkbox label='Fluid grid' onChange={value => this.setState({fluidGrid: value})} /> */}

				{this.state.create ? <CreateModal onClose={() => this.setState({create: false})} / > : null}
			</Grid>
		)
	}
}

/*---------------------------------------------*/

export default withRouter(HomeView);
