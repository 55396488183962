import React from 'react';

export default function UsersGallery({ users, show }) {
	if (!show) {
		return null;
	}

	return (
		<div id="users-gallery">
			{users.map((user, idx) => (
				<a
					key={`user-avatar-${idx}`}
					href={user.link}
					target="_blank"
					rel="noreferrer"
					alt="user"
					tabIndex={user.link ? 0 : -1}
				>
					<img src={user.image} aria-label={user.name} />
				</a>
			))}
		</div>
	);
}
