import React from 'react';
import UserDataContext from 'admin-kit-local/tools/context';
import AppContextMgr from 'admin-kit-local/tools/contextmgr';
import {Toaster} from 'designSystem';

import WebDashboard from 'pages/index.js';

/*---------------------------------------------*/

const DEFAULT_SETTINGS = {
	'title': 'Like us on Facebook',
	'subtitle': "It's the best way to stay in touch!",
	'template': {id: '1'},
	'sitePage': 'inffuse:all',
	'frequency': 'always',
	'language': 'en',
	'promotion': true
}

/*---------------------------------------------*/

class App extends React.Component {
	static contextType = UserDataContext;
	
	constructor() {
		super();

		this.contextMgr = new AppContextMgr(DEFAULT_SETTINGS);
		this.onDataChanged = this.onDataChanged.bind(this);
	}

	onDataChanged() {
		this.forceUpdate();
	}

	componentDidMount() {
		function setFavicon(location) {
			let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
			if (!link) {
				link = document.createElement('link');
				link.id = 'favicon';
				link.type = 'image/png';
				link.rel = 'shortcut icon';
				document.head.appendChild(link);
			}

			link.href = location;
		}
		
		document.title = 'Dashboard - LikePopup';
		setFavicon('/img/favicon.png');
		
		this.forceUpdate();
		this.contextMgr.init('fbpopup', () => {
			this.onDataChanged();
			if (this.contextMgr.ready()) {
				this.contextMgr.loadSites(() => this.forceUpdate());
			}
		});
	}

	/*-----*/

	render() {
		if (!this.contextMgr.ready())
			return null;

		return (
			<UserDataContext.Provider value={this.contextMgr}>
				<WebDashboard />
				<Toaster />
			</UserDataContext.Provider>
		)
	}
}

export default App;
