import React from 'react';

import BasePrivateView from 'pages/basePrivate.js';
import {Button,Heading,Form,Icon,Input,Select,Stack,Text} from 'designSystem';

import { withRouter } from "react-router-dom";
import FbButton from './fbButton.js';

/*---------------------------------------------*/

class CreateModal extends BasePrivateView {
	constructor() {
		super();

		this.state = {
			newSite: false,
			formData: {}
		};

		this.createProject = this.createProject.bind(this);
		this.createPopup = this.createPopup.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.connectFacebook = this.connectFacebook.bind(this);
	}

	createProject(siteId,popupName) {
		let projectKeyName = popupName && popupName.toLowerCase();
		var projectParams = {
			user: this.context.inffuse.user.id(),
			site: siteId,
			name: popupName,
			key_name: projectKeyName
		};

		return new Promise((resolve, _reject) => {
			this.context.inffuse.requestAPI('projects',projectParams,'POST').done((data) => {
				this.context.loadProject(data.project.id, (project) => {
					this.context.inffuse.project = project;
					this.setState({project: project});
					this.context.track('Popup Created',{id: project.id});

					resolve();
				});
			});
		});
	}

	connectFacebook() {
		this.context.inffuse.services.facebook.connect("pages_show_list",(data) => {
			this.context.inffuse.project.set('user',{
				id: data.user.id,
				name: data.user.name,
				photo: `https://graph.facebook.com/${data.user.id}/picture?access_token=${data.access_token}`
			});

			this.context.loadSites(() => this.redirect(`/projects/${this.state.project.id()}`));
			this.context.track('Facebook Connected');
		});
	}

	createPopup(params) {
		return new Promise((resolve, reject) => {
			if (params.siteId) {
				this.createProject(params.siteId,params.popupName).then(resolve);
			
			} else {
				let siteDomain = params.siteDomain;
				var siteKeyName = siteDomain.toLowerCase();

				if (siteKeyName.indexOf('://') >= 0) {
					siteKeyName = siteKeyName.substr(siteKeyName.indexOf('://')+3);
				}

				var siteParams = {
					user: this.context.inffuse.user.id(),
					domain: siteDomain,
					key_name: siteKeyName
				};
		
				this.context.inffuse.requestAPI('sites',siteParams,'POST').done(data => {
					this.createProject(data.site.id,params.popupName).then(resolve);
				});
			}
		});
	}

	onSubmit(values) {
		return this.createPopup(values);
	}

	render() {
		let sites = this.context.sites();
		let formIsValid = this.state.formData.popupName && (this.state.formData.siteId || this.state.formData.siteDomain);

		let domainInputTooltip = <span>The website domain on which<br/> the popup will be displayed.</span>;
		let siteInputs = sites.length ? 
			[
				<Select name='siteId' label='Choose site' onChange={(value) => this.setState({newSite: value === null})}>
					{ sites.map(site => <Select.Option value={site.id} label={site.domain} />) }
					<Select.Separator />
					<Select.Option label='Create new' value={null} />
				</Select>,
				<Input show={this.state.newSite} name='siteDomain' label='Site domain' placeholder='example.com' autocomplete='off' tooltip={domainInputTooltip} />
			] :
			[
				<Input name='siteDomain' label='Site domain' placeholder='example.com' autocomplete='off' tooltip={domainInputTooltip} />
			];

		let content = this.state.project
			? (
				<Form className='content' style={STYLES.form}>
					<Heading variant='heading3'>Continue with Facebook</Heading>
					<div style={{height: 'var(--space-stack-xl)'}} />
					<Text>Facebook Connect is required to let you choose your Facebook page and display page details on your popup (e.g. page name, page cover, number of likes)</Text>
					<div style={{height: 'var(--space-stack-xl)'}} />
					<Stack spacing='spaced'>
						<FbButton onClick={this.connectFacebook} />
					</Stack>
				</Form>
			)
			: (
				<Form className='content' style={STYLES.form} onSubmit={this.onSubmit} onChange={(data) => this.setState({formData: data})}>
					<Heading variant='heading3'>Create your Popup</Heading>
					<div style={{height: 'var(--space-stack-xl)'}} />
					<Stack spacing='spaced'>
						<Input name='popupName' label='Popup name' placeholder='My Popup' autocomplete='off' tooltip={<span>Give your popup a<br/> recognizable name.</span>} />
						{siteInputs}
						<Button variant='primary' disabled={!formIsValid} loading={Form.loading}>Continue</Button>
					</Stack>
				</Form>
			);

		return (
			<div style={STYLES.container}>
				<Icon style={STYLES.close} icon='close' size={40} color='--neutral-70' onClick={this.props.onClose} />				
				{content}
			</div>
		)
	}
}

/*---------------------------------------------*/

const STYLES = {
	container: {
		position: 'fixed',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,

		background: 'white'
	},

	form: {
		width: 320,
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translateX(-50%) translateY(-60%)'
	},

	close: {
		position: 'absolute',
		top: 40,
		right: 40,
		color: '--color-neutral-70'
	}
}

/*---------------------------------------------*/

export default withRouter(CreateModal);
