import React from 'react';

import BasePrivateView from 'pages/basePrivate.js';
import {Button,Heading,Form,Grid,Input} from 'designSystem';

import { withRouter } from "react-router-dom";


class AddSiteView extends BasePrivateView {
	constructor() {
		super();

		this.state = {};

		this.addSite = this.addSite.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	addSite(domain) {
		var key = domain.toLowerCase();
		if (key.indexOf('://') >= 0) {
			key = key.substr(key.indexOf('://')+3);
		}

		let sites = this.context.sites();
		for (var i=0; i < sites.length; i++)
			if (key === sites[i].key_name)
			{
				alert("domain already exists");
				return;
			}

		var params = {
			user: this.context.inffuse.user.id(),
			domain: domain,
			key_name: key
		};

		this.context.inffuse.site = this.props.project;
		this.context.inffuse.requestAPI('sites',params,'POST').done(data => {
			this.context.loadSites(() => this.redirect(`/sites/${data.site.id}`));
		});
	}

	onChange(values) {
		let errors = {};
		
		var key = (values.name && values.name.toLowerCase()) || '';
		if (key.indexOf('://') >= 0) {
			key = key.substr(key.indexOf('://')+3);
		}

		if (this.context.sites().find(site => site.key_name === key)) {
			errors['domain'] = 'Domain already in use';
		}
	}

	onSubmit(values) {
		this.addSite(values.domain);
	}

	render() {
		return (
			<Grid showGrid={this.state.showGrid} fluid={this.state.fluidGrid}>
				<Heading variant='heading1' grid-start={1} grid-end={8}>Add New Site</Heading>
				<div style={{height: 'var(--space-stack-s)'}} />
				<Form className='content' onChange={this.onChange} onSubmit={this.onSubmit}>
					<Input name='domain' label='Site domain' />
					<Button variant='primary'>Submit</Button>
				</Form>
			</Grid>
		)
	}
}


export default withRouter(AddSiteView);
