import React from 'react';

import {Heading} from 'designSystem';

export default function PageNotFoundView(props) {
	return (
		<div style={{margin: 32}}>
			<Heading>Page Not Found</Heading>
		</div>
	)
}