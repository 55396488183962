import React from 'react';
import {
	Redirect
} from "react-router-dom";

import BaseView from './base.js';


export default class BasePrivateView extends BaseView {
	render() {
		if (!this.context.loggedIn()) {
			return (
				<Redirect
					to={{
						pathname: "/user/login",
						search: "?next="+document.location.pathname
					}}
				/>
			);
		}

		return this.renderView();
	}
}