import React from 'react';
import UserDataContext from 'admin-kit-local/tools/context';

export default class BaseView extends React.Component {
	static contextType = UserDataContext;

	constructor() {
		super();

		this.togglePreview = this.togglePreview.bind(this);
	}

	redirect(path) {
		// const history = createBrowserHistory();
		// let history = useHistory();
		let history = this.props.history;

		console.log("Redirecting to ",path);
		history.push(path);
	}

	togglePreview(value) {
		this.context.showPreview = value;
		this.forceUpdate();
	}
}