import React,{useState,useRef} from 'react';
import {Button,Icon,Stack,Text} from 'designSystem';

/*---------------------------------------------*/

export default function Confirm(props) {
	const [isVisible,setVisible] = useState(false);
	const action = useRef(null);
	const actionArgs = useRef(null);

	const children = React.Children.map(props.children,(child,_index) => {
		const props = {
			onClick: () => {
				setVisible(true);

				action.current = child.props.onClick;
				actionArgs.current = arguments;
			}
		}

		return React.cloneElement(child, props);
	});

	return (
		<>
			{children}
			{!isVisible ? null : (
				<div style={STYLES.container}>
					<div style={STYLES.content}>
						<Icon style={STYLES.close} icon='close' size={24} color='--neutral-70' onClick={close} />				
		
						<Text style={{fontWeight: 'bold'}}>{props.title ?? 'Confirm'}</Text>
						<Stack.Spacer height='xs' />
						<Text>Please confirm you want to {props.action}.</Text>
						<Stack.Spacer height='m' />
						<div style={STYLES.footer}>
							<Button variant='common' onClick={close}>Cancel</Button>
							<Button variant='danger' onClick={confirm}>Confirm</Button>
						</div>
					</div>
				</div>
			)}
		</>
	)

	/*------------------------------------------*/

	function confirm() {
		action.current && action.current(actionArgs.current);
		close();
	} 

	function close() {
		setVisible(false);
	}
}

/*---------------------------------------------*/

const STYLES = {
	container: {
		position: 'fixed',
		background: 'rgba(0,0,0,0.4)',
		zIndex: 1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	
	content: {
		background: 'white',
		padding: '24px 32px',
		position: 'absolute',
		width: 400,
		left: '50%',
		top: '50%',
		borderRadius: 8,
		border: '1px solid var(--color-error-200)',
		transform: 'translateX(-50%) translateY(-50%)',
		textAlign: 'left'
	},
	
	footer: {
		textAlign: 'right',
		marginTop: 16
	},
	
	close: {
		position: 'absolute',
		top: 16,
		right: 16,
		color: '--color-neutral-70'
	}
}

/*---------------------------------------------*/

