import React from 'react';
import {Spinner} from 'designSystem';


/*---------------------------------------------*/

function Loader(props) {
	const style = {
		position: 'absolute',
		left:'50%',
		top:'50%',
		transform: 'translateY(-50%) translateX(-50%)'
	}

	return <Spinner style={style} size={48} />;
}

/*---------------------------------------------*/

export default Loader;
