import './7.scss';

import React from 'react';
import LikeButton from '../likebutton.js';
import Image from '../image.js';

import {ReactComponent as CloseButton} from '../img/close7.svg';
import {ReactComponent as IconsLeft} from '../img/template_7_icons_left.svg';
import {ReactComponent as IconsRight} from '../img/template_7_icons_right.svg';

const contentIcons = {
   left: IconsLeft,
   right: IconsRight
};

class Template extends React.Component {
   render() {
      const settings = this.props.settings;
      const fbPage = this.props.fbPage;

      return (
         <React.Fragment>
            <button id="close" onClick={this.props.onClose} aria-label="Close">
               <CloseButton />
            </button>

            <div id="content">
               {Object.entries(contentIcons).map(([side, url]) => (
                  <div
                     className={`background-image ${side}`}
                     style={{ backgroundImage: `url(${url})` }}
                     key={`background-image-${side}`}
                  />
               ))}
               <h2>{settings.title}</h2>
               <h3>{settings.subtitle}</h3>
            </div>
            <div id="footer">
               <div id="like-with-details" className="center-aligned">
                  <LikeButton pageId={fbPage.id} key={fbPage.id} />
                  <div className="details">{fbPage.prompt}</div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}

export default Template;
