import React from 'react';
import UserDataContext from './context.js';


export default class InffuseForm extends React.Component {
	static contextType = UserDataContext;

	constructor() {
		super();

		this.state = {
			settings: {}
		};

		this.onInputChange = this.onInputChange.bind(this);
	}
	
	componentDidMount() {
		let settings = this.props.project.get(this.props.settingsKey);
		settings = settings || this.context.defaultSettings;

		this.setState({settings: settings})
	}

	get(obj,path,default_value) { 
		try {
			// https://stackoverflow.com/a/40270942/390035
			let value = path.split('.').reduce((res,key) => res[key], obj);
			if (typeof value !== 'undefined')
				return value;
	
		} catch(error) {
		}
	
		return default_value;
	}
	
	set(obj,path,value) {
		let nodes = (typeof path === 'string') ? path.split('.') : path;
		let first = nodes.shift();
		if (typeof obj[first] === 'undefined')
			obj[first] = {};
		
		if (nodes.length)
			return this.set(obj[first],nodes,value);
		
		obj[first] = value;
	}

	onInputChange(value,path) {
		let settings = this.state.settings || {};
		this.set(settings,path,value);

		this.props.project.set(this.props.settingsKey,settings);
		this.context.isDirty(true);
		this.context.broadcastDataChange();
		this.context.track('Parameter Changed',{path,value});

		this.setState({settings});
	}

	render() {
		const wrapWithInffuse = (children) => {
			return React.Children.map(children,(component,index) => {
				if (!component.props) {
					return component;
				}

				let props = {
					children: wrapWithInffuse(component.props.children)
				}
	
				if (component && component.props && component.props.path) {
					let path = component.props.path;
					if (this.props.rootNode) {
						path = this.props.rootNode+'.'+path;
					}

					let value = this.get(this.state.settings,path);

					props.value = value;
					props.onChange = (value) => this.onInputChange(value,path);
				}

				return React.cloneElement(component, props);
			});
		}

		let children = wrapWithInffuse(this.props.children);
		
		return (
			<form style={this.props.style} onSubmit={this.onSubmit}>
				{children}
			</form>
		)
	}
}
