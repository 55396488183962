import React from 'react';
import moment from 'moment';

import {
	Link as RouteLink
} from "react-router-dom";

import {DataTable,Icon} from 'designSystem';
import Confirm from 'admin-kit-local/patterns/confirm';

import 'index.scss';

/*---------------------------------------------*/

export default function ProjectsList(props) {
	const COLUMNS = [
		{
			key: 'name',
			label: 'Name',
			render: (project) => <RouteLink to={`/projects/${project.id}`}>{project.name || project.key_name || 'Untitled'}</RouteLink>,
			width: "80%"
		},
		{
			key: 'created',
			label: 'Date Created',
			render: (project) => moment(project.created).format('D-MMM-YY'),
			width: "20%"
		},
		// {
		// 	key: 'site',
		// 	label: 'Site',
		// 	width: "20%"
		// },
		{
			key: 'actions',
			label: 'Actions',
			render: (project) => (
				<div className="menu right-aligned">
					<RouteLink to={`/projects/${project.id}`} style={{verticalAlign: 'top', marginTop: 1, display: 'inline-block'}}>
						<Icon icon='edit' size={16} onClick={null} />
					</RouteLink>
					
					<div style={{display: 'inline-block', width: 16}} />
					<Confirm action={`delete this popup`}>
						<Icon icon='trashCan' size={16} onClick={onDelete} />
					</Confirm>
				</div>
			),
			width: "60px"
		}
	];

	
	return (
		<DataTable columns={COLUMNS} data={props.projects} />
	)

	function onDelete() {
		props.deleteProject(props.project.id);
	}
}
