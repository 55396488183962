import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import UserDataContext from 'admin-kit-local/tools/context';
import {Grid,Heading,CodeSnippet,Text} from 'designSystem';


function InstallPage(props) {
	const context = useContext(UserDataContext);

	return (
		<Grid fluid nomargin>
			<Heading variant='heading3'>Install on your website</Heading>
			<div style={{height: 'var(--space-stack-m)'}} />

			<Text bold>Copy the following snippet and paste it above the closing <span style={{fontFamily: 'var(--typography-font-family-monospace)'}}>&lt;/BODY&gt;</span> tag of your web page</Text>
			<div style={{height: 'var(--space-stack-l)'}} />
			<CodeSnippet language='html' onCopy={onCopy}>
				{/* {'<script type="text/javascript" src="server.likepopup.com/js/loader.js"></script>'} */}
				{`<script src="https://app.likepopup.com/dist/js/likepopup.js" LikePopup="${props.project.id()}"></script>`}
				{`<link href="https://app.likepopup.com/dist/css/likepopup.css" rel="stylesheet" type="text/css" />`}
			</CodeSnippet>
		</Grid>
	)

	function onCopy() {
		context.track("Snippet Copied");
	}
}

/*---------------------------------------------*/

export default withRouter(InstallPage);
