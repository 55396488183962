import React from 'react';

import BasePrivateView from 'pages/basePrivate.js';
import {
	withRouter
} from "react-router-dom";

import DashboardTemplate from 'admin-kit-local/templates/dashboard';
import ContactDetailsPage from 'admin-kit-local/templates/account/details';
import SecurityDetailsPage from 'admin-kit-local/templates/account/security';
import BillingPage from 'admin-kit-local/templates/account/billing';
import PricingPage from './pricing';

/*---------------------------------------------*/

// inffuse app
// 1389642177718478
// 20878656da2a57e0aa9d5461c9da8a3a

//

/*---------------------------------------------*/

let PAGES = [
	{
		slug: '',
		content: ContactDetailsPage,
		menu: {
			icon: "blog",
			label: 'Contact Details',
			isActive: () => {
				let parts = document.location.pathname.replace(/\/$/,'').split('/');
				return parts.length === 3;
			}
		}
	},
	{
		slug: 'security',
		content: SecurityDetailsPage,
		menu: {
			icon: "password",
			label: 'Security'
		}
	},
	{
		slug: 'billing',
		content: BillingPage,
		menu: {
			icon: "purchase",
			label: 'Billing'
		}
	},
	{
		slug: 'billing/pricing',
		content: PricingPage
	},
]

/*---------------------------------------------*/

class AccountIndex extends BasePrivateView {
	renderView() {
		return (
			<DashboardTemplate 
				menuTitle='Account'
				basePath='/user/account'
				pages={PAGES} 
			/>
		)
	}
}
 
/*---------------------------------------------*/

export default withRouter(AccountIndex);
