import React, {useContext,useState} from 'react';
import {Link as RouterLink,withRouter} from "react-router-dom";

import UserDataContext from 'admin-kit-local/tools/context';
import Avatar from 'admin-kit-local/components/avatar';
import {OverflowMenu,Grid} from 'designSystem';


function Header(props) {
	const context = useContext(UserDataContext);
	const avatar = <Avatar name={context.user().name()} />;

	const [customContent,setCustomContent] = useState(null);
	context.onHeaderContentChange(setCustomContent);

	const action = context.loggedIn() ?
		<OverflowMenu target={avatar} style={{verticalAlign: 'middle', marginLeft: 16}} menuAlign='right'>
			<OverflowMenu.Item onClick={() => goto("/user/account")}>Account</OverflowMenu.Item>
			<OverflowMenu.Item onClick={() => goto("/contact")}>Contact Us</OverflowMenu.Item>
			<OverflowMenu.Separator />
			<OverflowMenu.Item label="Sign out" onClick={logout} />
		</OverflowMenu> :
		<RouterLink to="/user/login"><p><b>Login</b></p></RouterLink>;

	return (
		<div id="header">
			<Grid style={{fontSize: 0}} fluid align='center'>
				<Grid.Container gridEnd={3}>
					<RouterLink to="/"><img className='logo' style={{height: 24, maxWidth: 178}} src={props.logo} alt='logo' /></RouterLink>
				</Grid.Container>
				<Grid.Container gridStart={4} style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
					{customContent}
					<div className="menu" style={{gridColumnStart: 14}}>
						{action}
					</div>
				</Grid.Container>
			</Grid>
		</div>
	)

	/*------------------------------------------*/

	function goto(url) {
		context.redirect(props.history,url);
	}

	function logout() {
		context.logout();
	}
}


export default withRouter(Header);
