// TODO
// switch to dynamic class names (import style ... style.className)

import React from 'react';
import $ from 'jquery';
import InffuseSDK from './libs/inffuse.js';

import './widget.scss';

import defaultProfilePhoto from 'img/default_images/template_4_default_profile.png';
import defaultCoverPhoto from 'img/default_images/template_5_default_cover.png';

/*---------------------------------------------*/

import Template1 from './templates/1.js';
import Template2 from './templates/2.js';
import Template3 from './templates/3.js';
import Template4 from './templates/4.js';
import Template5 from './templates/5.js';
import Template6 from './templates/6.js';
import Template7 from './templates/7.js';
import Template8 from './templates/8.js';
import Template9 from './templates/9.js';

/*---------------------------------------------*/

const APP_LINK = 'http://links.inlightlabs.com/fbpopup';
const FB_SCRIPT_ID = 'popup-facebook-jssdk';

const TEMPLATES = {
	1: Template1,
	2: Template2,
	3: Template3,
	4: Template4,
	5: Template5,
	6: Template6,
	7: Template7,
	8: Template8,
	9: Template9
};

const DEFAULT_USERS = [
	...new Array(10).fill({ image: 'https://inffuse-fbpopup.appspot.com/img/profile_male.jpg' }),
	...new Array(10).fill({ image: 'https://inffuse-fbpopup.appspot.com/img/profile_female.jpg' })
]
	.map((a) => [Math.random(), a])
	.sort((a, b) => a[0] - b[0])
	.map((a) => a[1]);

const DEFAULT_PAGE_DETAILS = {
	id: '363341117461804',
	name: 'Like Popup',
	fan_count: 32399,
	users: DEFAULT_USERS,
	cover: defaultCoverPhoto,
	profile: defaultProfilePhoto
};

/*---------------------------------------------*/

const initInffuse = (projectId, accessToken, callback) => {
	let onInffuseLoaded = (inffuse) => {
		window.inffuse = inffuse;

		callback && callback(inffuse);
	};

	var app = 'fbpopup';
	// var server = '//alpha-dot-inffuse-platform.appspot.com';
	// var server = '//inffuse-platform.appspot.com';
	var server = '//platform.inffuse.com';
	// var app = 'calendar_dev';
	// var server = 'http://10.0.0.9:10099';
	// var server = "http://192.168.1.22:10099";

	let inffuseSDK = new InffuseSDK($.ajax, app);
	inffuseSDK.server = server;
	inffuseSDK.context_params = {
		platform: 'web',
		id: projectId,
		access_token: accessToken
	};

	inffuseSDK.init(onInffuseLoaded);
};

/*---------------------------------------------*/

class LikePopup extends React.Component {
	constructor() {
		super();

		this.state = {};

		this.lastRequestedPageId = null;
		this.onPromotionClicked = this.onPromotionClicked.bind(this);

		this.setPageDetails = this.setPageDetails.bind(this);
		this.loadFacebookPage = this.loadFacebookPage.bind(this);
	}

	/*-----*/

	componentDidMount() {
		let initFacebook = () => {
			const appId = '639530319449893';
			const script = document.createElement('script');
			script.id = FB_SCRIPT_ID;
			script.src = `//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=${appId}&rand=${Math.random()}`;
			script.async = true;
			document.body.appendChild(script);
		};

		let onInffuseReady = (inffuse) => {
			this.inffuse = inffuse;

			this.setState({ settings: this.inffuse.project.get('settings') });
			// this.inffuse.on("data-changed",() => {
			// 	this.setState({settings: this.inffuse.project.get('settings')});
			// });
		};

		setTimeout(initFacebook, 100);

		if (this.props.inffuse) {
			onInffuseReady(this.props.inffuse);
		} else {
			initInffuse(this.props.id, this.props.accessToken, onInffuseReady);
		}
	}

	componentWillUnmount() {
		var script_node = document.getElementById(FB_SCRIPT_ID);
		script_node && document.body.removeChild(script_node);
	}

	onPromotionClicked() {}

	/*-----*/

	setPageDetails(fbPage) {
		fbPage.prompt = `${fbPage.fan_count.toLocaleString()} likes`;
		this.setState({ fbPage: fbPage });
	}

	loadFacebookPage(pageId) {
		const onError = () => {
			this.setPageDetails(DEFAULT_PAGE_DETAILS);
		};

		if (this.fbPage && pageId === this.fbPage.id) {
			return; // nothing to do, page details were already loaded
		}

		if (!this.inffuse) {
			return;
		}

		this.lastRequestedPageId = pageId;
		this.inffuse.services.facebook
			.page(pageId)
			.done((data) => {
				if (data.page.error) {
					return onError();
				}

				var page = data.page;
				let fbPage = {
					id: pageId,
					users: page.users || DEFAULT_USERS,
					profile: page.picture && page.picture.data.url,
					fan_count: page.fan_count,
					direction: page.direction,
					name: page.name,
					cover: page.cover && page.cover.source
				};

				this.setPageDetails(fbPage);
			})
			.fail(() => {
				onError();
			});
	}

	render() {
		let settings = this.props.settings || this.state.settings;
		if (!settings) {
			return 'Loading...';
		}

		let templateId = settings.template.id;
		let Template = TEMPLATES[templateId];
		let currentPageId = settings && settings.fbpage && settings.fbpage.id;
		let lastPageId = this.lastRequestedPageId;

		if (currentPageId !== lastPageId) {
			this.loadFacebookPage(currentPageId);
			return null;
		}

		let fbPage = this.state.fbPage;
		if (!fbPage) {
			return null;
		}

		return (
         <div id={`template-${templateId}`} className={`likepopup-container breakpoint-${this.props.breakpoint}`} ref={this.props.contentRef}>
				<div className="template-container">
					<Template settings={settings} fbPage={fbPage} onClose={this.props.onClose} cdn={this.props.embedded} />
				</div>

				<div id="promotion" if="showPromotion() && !settings.minimize">
					Popup by{' '}
					<a href={APP_LINK} target="_blank" rel="noreferrer" onClick={this.onPromotionClicked}>
						LikePopup.com
					</a>
				</div>
			</div>
		);
	}
}

/*---------------------------------------------*/

export { LikePopup };
