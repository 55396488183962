import React from 'react';

import BasePrivateView from './basePrivate.js';
import {Button,Form,Grid,Heading,Input} from 'designSystem';

import { withRouter } from "react-router-dom";


class CreateProjectView extends BasePrivateView {
	constructor() {
		super();

		this.state = {};

		this.create = this.create.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	create(userId,siteId,projectName,projectKey) {
		var params = {
			user: userId,
			site: siteId,
			name: projectName,
			key_name: projectKey
		};

		this.context.inffuse.requestAPI('projects',params,'POST').done((data) => {
			this.site.projects.push(data.project);
			this.redirect(`/projects/${data.project.id}`);
		});
	}

	onChange(values) {
		let errors = {};
		
		var key = (values.name && values.name.toLowerCase()) || '';
		if (key.indexOf('://') >= 0) {
			key = key.substr(key.indexOf('://')+3);
		}

		if (this.context.sites().find(site => site.key_name === key)) {
			errors['domain'] = 'Domain already in use';
		}
	}

	onSubmit(values) {
		let userId = this.context.inffuse.user.id();
		let name = values.name;
		var keyName = name && name.toLowerCase();
		
		this.create(userId,this.site.id,name,keyName);
	}

	render() {
		if (!this.site && this.context.sites()) {
			let siteId = this.props.match.params.siteId;
			this.site = this.context.sites().find(site => site.id === siteId);
		}

		return (
			<Grid showGrid={this.state.showGrid} fluid={this.state.fluidGrid}>
				<Heading variant='heading1' grid-start={1} grid-end={8}>Create New {this.context.cfg.project.name}</Heading>
				<div style={{height: 'var(--space-stack-s)'}} />
				<Form className='content' onChange={this.onChange} onSubmit={this.onSubmit}>
					<Input name='name' label={`${this.context.cfg.project.name} name`} />
					<Button variant='primary'>Submit</Button>
					{this.state.done && "DONE!!!"}
				</Form>
			</Grid>
		)
	}
}


export default withRouter(CreateProjectView);
