import React from 'react';
import { withRouter } from 'react-router-dom';

import BaseView from 'pages/base.js';
import {Button,Form,Grid,Heading,Input,Stack,Text} from 'designSystem';
import InffuseForm from 'admin-kit-local/tools/inffuseform';


class TextPage extends BaseView {
	render() {
		return (
			<Grid fluid nomargin>
				<div style={{display: 'grid', gridTemplateColumns: 'auto min-content'}}>
					<div>
						<Heading variant='heading3'>Content</Heading>
						<div style={{height: 'var(--space-stack-m)'}} />
						<Text bold>Here you can edit the text that will appear in the popup</Text>
						<div style={{height: 'var(--space-stack-l)'}} />

						<InffuseForm project={this.props.project} settingsKey='settings'>
							<Stack spacing="comfortable" style={{maxWidth: 400}}>
								<Input path='title' label='Popup title' type='text' maxLength='settings.template.id == 2 ? 25 : 60' key='title' />
								<Input path='subtitle' label='Popup subtitle' type='textarea' maxLength='130' key='subtitle' />

								<Input path='page_name' label='Facebook page name' maxLength='200' key='page_name' />
							</Stack>
						</InffuseForm>
					</div>
					<div style={{textAlign: 'right'}}>
						<Form silent onSubmit={this.props.onPublish} style={{textAlign: 'right'}}>
							<Button variant='primary' loading={Form.loading} disabled={!this.context.isDirty()} >Publish</Button>
						</Form>
						<div style={{height: 'var(--space-stack-m)'}} />
						{this.props.previewComponent}
					</div>
				</div>
			</Grid>
		)
	}
}

/*---------------------------------------------*/

export default withRouter(TextPage);
