import React from 'react';
import {useContext,useEffect} from 'react';
import UserDataContext from 'admin-kit-local/tools/context';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	withRouter,
} from "react-router-dom";

import SignupView from 'admin-kit-local/templates/user/signup';
import LoginView from 'admin-kit-local/templates/user/login';
import ForgotPasswordView from 'admin-kit-local/templates/user/forgotpassword';
import ResetPasswordView from 'admin-kit-local/templates/user/resetpassword';

import ContactUsPage from 'admin-kit-local/templates/contactus.js';

import PageNotFoundView from 'admin-kit-local/templates/pageNotFound.js';
// import Header from 'admin-kit-local/patterns/header';
import Header from 'admin-kit-local/patterns/header.js';
import HomeView from './home.js';

import CreateProjectView from './createproject.js';
import SitePage from './site/index.js';
import AddSiteView from './site/add.js';

import ProjectIndex from './project/index.js';
import AccountDashboard from './account/index.js';

/*---------------------------------------------*/

const InnerPageTemplate = (props) => [
	<Header logo={props.logo} key='header'></Header>,
	<div className="main" key='main'>
		{props.children}
	</div>
]

/*---------------------------------------------*/

function PageRouter(props) {
	const context = useContext(UserDataContext);

	useEffect(() => {
		context.track('Location Changed', {location: props.location.pathname});
	 }, [props.location]);

	/*-----*/

	const logo = `/img/logo.svg`;
	return (
		<Switch>
			<Route exact path="/" key='home'>
				<InnerPageTemplate logo={logo}>
					<HomeView />
				</InnerPageTemplate>
			</Route>
			<Route exact path="/contact" key='home'>
				<InnerPageTemplate logo={logo}>
					<ContactUsPage />
				</InnerPageTemplate>
			</Route>
			<Route path="/sites/:siteId/new" key='create-project'>
				<InnerPageTemplate logo={logo}>
					<CreateProjectView />
				</InnerPageTemplate>
			</Route>
			<Route path="/sites/new" key='add-site'>
				<InnerPageTemplate logo={logo}>
					<AddSiteView />
				</InnerPageTemplate>
			</Route>
			<Route path="/sites/:id" key='site'>
				<InnerPageTemplate logo={logo}>
					<SitePage />
				</InnerPageTemplate>
			</Route>
			<Route path="/projects/:id" key='project'>
				<InnerPageTemplate logo={logo}>
					<ProjectIndex />
				</InnerPageTemplate>
			</Route>
			<Route path="/user/signup" key='signup'>
				<SignupView />
			</Route>
			<Route path="/user/login" key='login'>
				<LoginView />
			</Route>
			<Route path="/user/forgot-password" key='forgot-password'>
				<ForgotPasswordView />
			</Route>
			<Route path="/user/reset-password" key='reset-password'>
				<ResetPasswordView />
			</Route>
			<Route path="/user/account" key='account'>
				<InnerPageTemplate logo={logo}>
					<AccountDashboard />
				</InnerPageTemplate>
			</Route>
			<Route path='*' exact={true} key='not-found'>
				<InnerPageTemplate logo={logo}>
					<PageNotFoundView />
				</InnerPageTemplate>
			</Route>
		</Switch>
	)
}

const PageRouterPublic = withRouter(PageRouter);

/*---------------------------------------------*/

class WebDashboard extends React.Component {
	render() {
		return (
			<Router>
				<PageRouterPublic />
			</Router>
		)
	}
}

/*---------------------------------------------*/

export default WebDashboard;
