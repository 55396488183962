import React from 'react';


function Image(props) {
	let cdnSource = props.src;
	if (cdnSource.substr(0,5) !== 'data:' && cdnSource.substr(0,4) !== 'http') {
		cdnSource = cdnSource.replace('./static','').replace('/static','');
		cdnSource = 'https://app.likepopup.com/dist/'+cdnSource;
	}
	
	let imageProps = {
		...props,
		src: props.cdn ? cdnSource : props.src
	}

	return <img {...imageProps} />
}


export default Image;