import './1.scss';
import { ReactComponent as CloseIcon } from '../img/close6.svg';

import React from 'react';
import LikeButton from '../likebutton.js';
import UsersGallery from '../components/UsersGallery';

class Template extends React.Component {
	render() {
		const settings = this.props.settings;
		const fbPage = this.props.fbPage;

		return (
			<React.Fragment>
				<button id="close" onClick={this.props.onClose} aria-label="Close">
					<CloseIcon />
				</button>

				<h2>{settings.title}</h2>
				<h3>{settings.subtitle}</h3>

				<div id="like-with-details">
					<LikeButton pageId={fbPage.id} key={fbPage.id} />
					<div className="details" content="fbPage.prompt | customPageName:settings.page_name | unsafe" />
				</div>

				<UsersGallery users={fbPage.users} show={settings.showAvatars} />

				<hr />
			</React.Fragment>
		);
	}
}

export default Template;
