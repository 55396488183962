import React from 'react';
import { useContext } from "react";
import { withRouter } from 'react-router-dom';

import {Button,Form,Grid,Heading,Stack,Text,Thumbnails,Toaster} from 'designSystem';
import InffuseForm from 'admin-kit-local/tools/inffuseform';
import UserDataContext from 'admin-kit-local/tools/context';

/*---------------------------------------------*/

function Templates(props) {
	const context = useContext(UserDataContext);

	let templates = [
		{id: '1', label: 'Template 1', premium: false},
		{id: '8', label: 'Template 8', premium: false},
		{id: '2', label: 'Template 2', premium: true},
		{id: '3', label: 'Template 3', premium: true},
		{id: '4', label: 'Template 4', premium: true},
		{id: '5', label: 'Template 5', premium: true},
		{id: '6', label: 'Template 6', premium: true},
		{id: '7', label: 'Template 7', premium: true},
		{id: '9', label: 'Template 9', premium: true}
	]

	return (
		<Grid fluid nomargin>
			<div style={{display: 'grid', gridTemplateColumns: 'auto min-content'}}>
				<div>
					<Heading variant='heading3'>Templates</Heading>
					<div style={{height: 'var(--space-stack-m)'}} />
					<Text bold>Choose popup template</Text>
					<div style={{height: 'var(--space-stack-l)'}} />

					<Stack spacing="comfortable" style={{maxWidth: 400}}>
						<InffuseForm project={props.project} settingsKey='settings'>
							<TemplatesThumbnails path='template.id' label="" templates={templates} site={props.site} />
						</InffuseForm>
					</Stack>
				</div>
				<div grid-start={8} style={{textAlign: 'right'}}>
					<Form silent onSubmit={props.onPublish} style={{textAlign: 'right'}}>
						<Button variant='primary' loading={Form.loading} disabled={!context.isDirty()} >Publish</Button>
					</Form>
					<div style={{height: 'var(--space-stack-m)'}} />
					{props.previewComponent}
				</div>
			</div>
		</Grid>
	)
}

/*---------------------------------------------*/

function TemplatesThumbnails(props) {
	const context = useContext(UserDataContext);
	const isFreeUser = !props.site.plan;

	return (
		<Thumbnails value={props.value} label="" onChange={onChange}>
			{
				props.templates.map(templates => (
					<Thumbnails.Item 
						value={templates.id} 
						label={templates.label} 
						badge={templates.premium && isFreeUser ? 'Premium' : null}
						key={templates.id}
						img={`https://inffuse-fbpopup.appspot.com/img/templates/t${templates.id}.svg`} 
						onMouseEnter={templates.premium && isFreeUser ? (id => onPreviewStart(id)) : undefined}
						onMouseLeave={templates.premium && isFreeUser ? (id => onPreviewEnd()) : undefined}
						// imgSelected={`https://inffuse-fbpopup.appspot.com/img/templates/t${templates.id}hover.svg`} 
					/>
				))
			}
		</Thumbnails>
	)

	function onChange(value) {
		const template = props.templates.find(t => t.id === value);
		if (isFreeUser && template.premium) {
			Toaster.add({content: 'This is a premium template. Please upgrade to use it.'});
			return false;
		}

		props.onChange && props.onChange(value);
	}

	function onPreviewStart(id) {
		context.inffuse.broadcast('template-preview', id);
	}

	function onPreviewEnd() {
		context.inffuse.broadcast('template-preview', null);
	}
}

/*---------------------------------------------*/

export default withRouter(Templates);
