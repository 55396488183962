import './card.scss';

import React from 'react';
import {withRouter} from 'react-router-dom';

import {Link} from 'designSystem';

function CardTemplate(props) {
	return (
		<div className='card-template'>
			<div></div>
			<div>
				<img className='logo' src={`/img/logo.svg`} alt='logo' />
				{props.children}
			</div>
			<div className='footer'>
				<Link monochrome size='small' href='https://likepopup.com' target='_blank'>LikePopup.com</Link>
				<span className='separator' />
				<Link monochrome size='small' href='https://likepopup.com/faq' target='_blank'>Help Center</Link>
				<span className='separator' />
				<Link monochrome size='small' href='https://likepopup.com/contact' target='_blank'>Contact Support</Link>
				<span className='separator' />
				<Link monochrome size='small' href='https://likepopup.com/legal/privacy' target='_blank'>Privacy Policy</Link>
			</div>
		</div>
	)
}

export default withRouter(CardTemplate);
