import './3.scss';

import React from 'react';

import LikeButton from '../likebutton.js';
import {ReactComponent as CloseButton} from '../img/close3.svg';
import {ReactComponent as LikeThumb} from '../img/template_3_like_thumb.svg';
import UsersGallery from '../components/UsersGallery';
import Image from '../image.js';

class Template extends React.Component {
	render() {
		const settings = this.props.settings;
		const fbPage = this.props.fbPage;

		return (
			<React.Fragment>
				<button id="close" onClick={this.props.onClose} aria-label="Close">
					<CloseButton />
				</button>

				<LikeThumb alt="like" />
				<div>
					<h2>{settings.title}</h2>
					<h3>{settings.subtitle}</h3>
				</div>

				<div id="bottom-box">
					<div id="like-with-details">
						<LikeButton pageId={fbPage.id} key={fbPage.id} />
						<div className="details" content="fbPage.prompt | customPageName:settings.page_name | unsafe">
							652,829 people like this page
						</div>
					</div>

					<UsersGallery users={fbPage.users} show={settings.showAvatars} />
				</div>

				<hr />
			</React.Fragment>
		);
	}
}

export default Template;
