import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';

import {Button,Checkbox,Heading,Divider,Input,Form,Link,SocialButton,Stack,Text} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';
import CardTemplate from '../card.js';

function LoginView(props) {
	const context = useContext(UserDataContext);
	if (context.loggedIn()) {
		context.redirect(props.history,'/');
	}

	return (
		<CardTemplate>
			<div className='card'>
				<Heading key='heading' variant='heading5'>Login to your account</Heading>
				<br />
				<Stack spacing='comfortable' style={{textAlign: 'left'}}>
					<Form onSubmit={onSubmit} key='form'>
						<Stack spacing='comfortable' style={{textAlign: 'left'}}>
							<Input label='Email' type='text' name='email' />
							<Input label='Password' type='password' name='password' />
							<Checkbox label='Remember me' />
							<Button variant="primary" fullwidth loading={Form.loading}>Login</Button>
						</Stack>
						{/* <Stack.Spacer height='m' />
						<Divider label='or' />
						<Stack.Spacer height='m' />
						<SocialButton fullwidth type="button" onClick={googleConnect}>Connect with Google</SocialButton> */}
					</Form>
					<div style={{textAlign: 'center'}}>
						<Link href='/user/forgot-password'>Forgot password?</Link>
					</div>
				</Stack>
			</div>
			<div style={{marginTop: 8}}>
				<Text>Don't have an account?</Text>
				&nbsp;
				<Link href='/user/signup'>Sign up</Link>
			</div>
		</CardTemplate>
	)

	/*------------------------------------------*/

	function onSubmit(data) {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const queryParams = Object.fromEntries(urlSearchParams.entries());
		
		let nextUrl = queryParams.next || '/';
		return context.login(data.email,data.password).then(() => context.redirect(props.history,nextUrl));
	}

	function googleConnect() {
		// context
	}
}

export default withRouter(LoginView);
