import React from 'react';
import {useState,useContext} from "react";

import {Button,Grid,Heading,Input,Form,Stack} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';
import withLogin from 'admin-kit-local/tools/withLogin';

import 'index.scss';

/*---------------------------------------------*/

function SecurityDetailsPage(props) {
	const context = useContext(UserDataContext);

	const [passwords,setPasswords] = useState({});
	const [_formError,setFormError] = useState(false);

	const passwordsMissing = (!passwords.current_password || !passwords.password || !passwords.confirm_password);
	const passwordsMismatch = passwords.password && passwords.confirm_password && (passwords.password !== passwords.confirm_password);

	return (
		<Grid fluid>
			<Heading variant='heading3'>Security Details</Heading>
			<div style={{height: 'var(--space-stack-xl)'}} />
			<Heading variant='heading5'>Change Password</Heading>
			<div style={{height: 'var(--space-stack-xl)'}} />
			<Form onSubmit={onPasswordsSubmit} onChange={onPasswordsChange} key='password-form'>
				<Stack spacing='normal' style={{textAlign: 'left', maxWidth: 300}}>
					<Input label='Current password' type='password' name='current_password' />
					<Input label='New password' type='password' name='password' />
					<Input label='Confirm password' type='password' name='confirm_password' error={passwordsMismatch} message={passwordsMismatch && 'Passwords don\'t match'} />
					
					<Button loading={Form.loading} disabled={passwordsMissing}>Save</Button>
				</Stack>
			</Form>
		</Grid>
	)

	/*------------------------------------------*/

	function onPasswordsSubmit(values) {
		return new Promise((resolve, _reject) => {
			context.inffuse.user.update({password: values.password, current_password: values.current_password})
				.then(result => {})
				.fail(obj => {
					setFormError(obj.responseJSON.error)
				})
				.always(resolve);
		});
	} 

	function onPasswordsChange(values) {
		setPasswords(values);
	}
}

/*---------------------------------------------*/

export default withLogin(SecurityDetailsPage);
