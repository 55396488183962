import React from 'react';

export default class LikeButton extends React.Component {
	constructor() {
		super();

		this.pageId = false;
	}

	render() {
		if (this.pageId !== this.props.pageId) {
			this.pageId = this.props.pageId;
			window.FB && setTimeout(() => window.FB.XFBML.parse(document.getElementById('template-container')));
		}

		return (
			<div 
				className="fb-like" 
				data-ref="Facebook-Like-Popup" 
				data-href={`https://www.facebook.com/${this.pageId}`} 
				xdata-size="large" 
				data-width="10" 
				data-layout="button" 
				data-action="like"
				data-show-faces="true"
				data-share="false">
			</div>
		)
	}
}