import React from 'react';

let Avatar = (props) => {
	const getInitials = (name) => {
		if (!name) {
			return 'U'; // "Unnamed'
		}

		let parts = name.split(' ');
		return parts.map(part => part[0].toUpperCase()).join('');
	}

	return (
		<div className='avatar' onClick={props.onClick} style={STYLES.container}>
			{getInitials(props.name)}
		</div>
	)
}

const STYLES = {
	container: {
		width: 32,
		height: 32,
		lineHeight: '32px',
		fontSize: 16,
		textAlign: 'center',
		borderRadius: 100,
		cursor: 'pointer',
		color: 'var(--color-neutral-100)',
		background: 'var(--color-neutral-700)',
	}
}

export default Avatar;