import React, { useEffect } from 'react';
import {useState,useContext} from "react";

import moment from 'moment';
import {Button,DataTable,Grid,Heading} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';

import withLogin from 'admin-kit-local/tools/withLogin';
import Loader from 'admin-kit-local/components/loader';

import 'index.scss';

/*---------------------------------------------*/

function BillingPage(props) {
	const COLUMNS = [
		{
			key: 'site',
			label: 'Site',
			render: (sub) => sub.site.domain || 'Untitled'
		},
		{
			key: 'plan',
			label: 'Plan',
			render: (sub) => <div className="badge">{sub.plan}</div>
		},
		{
			key: 'status',
			label: 'Status',
			render: (sub) => sub.enddate ? 'Cancelled' : 'Active'
		},
		{
			key: 'start',
			label: 'Start Date',
			render: (sub) => moment(sub.date).format('D-MMM-YYYY')
		},
		{
			key: 'enddate',
			label: 'End Date',
			render: (sub) => sub.enddate ? moment(sub.enddate).format('D-MMM-YYYY') : null
		},
		{
			key: 'amount',
			label: 'Amount',
			render: (sub) => `$${sub.amount}/${sub.period || 'month'}`
		},
		{
			key: 'actions',
			label: '',
			render: (sub) => (
				<div className="right-aligned">
					<Button variant='outline' size='small'>Cancel</Button>
				</div>
			)
		}
	];


	const context = useContext(UserDataContext);
	const [subscriptions,setSubscriptions] = useState(null);
	
	useEffect(() => {
		const user_id = context.inffuse.user.id()
		context.inffuse.requestAPI('sales',{user: user_id, filters: JSON.stringify({user: user_id})})
			.then((result) => {
				setSubscriptions(result.sales);
			})
	},[context.inffuse]);

	let content = null;
	
	if (!subscriptions) {
		content = <Loader />;
		
	} else {
		if (!subscriptions.length) {
			content = <Heading variant='heading5'>You don't have any active subscriptions</Heading>
		
		} else {
			content = <DataTable columns={COLUMNS} data={subscriptions} />
		}
	}

	return (
		<Grid fluid>
			<Heading variant='heading3'>Billing</Heading>
			<div style={{height: 'var(--space-stack-m)'}} />
			{content}
		</Grid>
	)
}

/*---------------------------------------------*/

export default withLogin(BillingPage);
