function InffuseSDK_01(http,app_id)
{
        var instance = this;
        var self = this;

        this.app_id = app_id;
        this.http = http;
        this.loaderVersion = "0.1";
        this.server = "https://inffuse-platform.appspot.com";
        this.context_params = null;
        
        var EVENT_HANDLERS = {};
        
        /*------------------------------------------*/
        /*------------------------------------------*/

        self.ui = new self.UI(self);
        self.utils = new self.Utils(self);

        /*------------------------------------------*/
        
        this.getContext = function(callback,callback_error) {
                var query_params = self.utils.buildQuery(self.context_params);
                var data = self.context_params || {};

                if (typeof document != 'undefined') { // browser 
                        query_params = query_params || document.location.search.slice(1);
                        data["_referrer"] = encodeURIComponent(document.referrer);
                        data["_origin"] = encodeURIComponent(document.location.href); // referer and origin are sometimes blocked, for instance in FF private session
                }
                
                var url = self.server+"/js/v"+self.loaderVersion+"/"+app_id+"/data?"+(query_params||'');
                self.utils.http({
                        url: url,
                        // contentType: 'application/json',
         data: JSON.stringify(data),
                        xhrFields: {
                                withCredentials: true
                        },
                        type: 'POST',
                        success: function(context){
                                callback(context);
                        },
                        error: function(status,response) {
                                if (status == 303) {
                                        document.location.href = response.redirect_url;
                                        return;
                                }

                                if (callback_error)
                                        callback_error(status,response);
                        }
                });
        }

        this.initWithContext = function(context,callback) {
                self.app = new self.App(instance, context.app.meta, context.app.data);
                self.platform = context.platform;
                self.server     = context.server;
                self.apiVersion = context.api_version;
                self.editing = context.editing;
                self.external_script = context.external_script;
                self._viewMode = context.view_mode;

                if (context.user)
                        self.user = new self.UserClass(self, context.user.meta, context.user.data);
                else
                        self.user = new self.UserClass(self,{},{});

                
                if (context.user) {
                        if (context.site)
                                self.site = new self.Site(self, context.site.meta);

                        if (context.project)
                                self.project = new self.ProjectClass(self, context.project.meta, context.project.data, context.project.flags);
                }
                
                self.projects = new self.ProjectsClass(self);
                self.quotas = new self.Quotas(self, context.quotas);
                
                self.services = new self.Services(self, context.services);
                self.stripe = new self.StripeClass(self);
                self.fastspring = new self.FastspringClass(self);
                
                switch (self.platform) {
                        case 'wix':
                                self.wix = new self.WixClass(self);
                                self.wix.init();
                                break;

                        case 'weebly':
                                self.weebly = new self.WeeblyClass(self);
                                self.weebly.init();
                                break;

                        case 'shopify':
                                self.shopify = new self.ShopifyClass(self);
                                self.shopify.init(context.shopify);
                                break;
                }


                if (typeof callback != "undefined")
                        callback(self,context);

                self.ready();
        }

        /*------------------------------------------*/

        this.init = function(callback,callback_error)
        {
                self.getContext(function(context) {
                        self.initWithContext(context,callback);
                },callback_error);
        }

        /*------------------------------------------*/

        this.message = function(str)
        {
                console.log(str);
        }

        this.logData = function()
        {
                console.log("version: "+ this.loaderVersion + ", appID: "+ this.app_id);
        }

        // this.getContextData = function()
        // {
        //      return self.contextData;
        // }
        
        /*------------------------------------------*/

        /**
         * Request a callback when Inffuse is fully loaded and ready for work
         * @method ready
         * @param {function} onready Called when Inffuse is ready
         */
        this.ready = function(onready)
        {
                if (typeof onready != 'undefined')
                {
                        if (self.isready)
                                onready(self);
                        else {
                                if (typeof self.readyCallbacks == 'undefined')
                                        self.readyCallbacks = [];
                                
                                self.readyCallbacks.push(onready);
                        }

                        return;
                }
                
                if (self.readyCallbacks)
                {
                        for (var i in self.readyCallbacks)
                                self.readyCallbacks[i](self);
                }
                
                self.broadcast("ready");
                self.isready = true;
        }

        /**
         * @method viewMode
         * @returns {string}    Returns the current viewing mode. Valid values - [editor/preview/site]
         */
        this.viewMode = function() {
                return this._viewMode;
        }

        /** 
         * @private
         */
        this.error = function(msg)
        {
                var full_msg = "[Inffuse error] " + msg + '.';
                if (window.console)
                        console.error(full_msg);
        }

        /*------------------------------------------*/

        /**
         * Subscribe to event from Inffuse or to a custom event.
         * @method on
         * @param {string} event Event name
         * @param {function} handler Called when the event is triggered
         * @param {object} data Event data
         * @example Inffuse event
         *      Inffuse.on("data-changed",function(new_data) 
         *      {
         *              if (new_data.hasOwnProperty("my_key"))
         *              {
         *                      // Do something
         *                      return;
         *              }
         *      });
         * @example Custom event
         *      Inffuse.broadcast("my_custom_event",{weight: 78; height: 180; alias: "Johnny"});
         *
         *      Inffuse.on("my_custom_event",function(user) {
         *              console.log("User Alias=%s, User Height=%d", user.alias, user.height)
         *      });
         */
        this.on = function(event,handler)
        {
                if (!handler || typeof handler != "function") {
                        throw "[Inffuse] Invalid handler passed (function is required)";
                        return;
                }

                if (typeof EVENT_HANDLERS[event] == 'undefined')
                        EVENT_HANDLERS[event] = [];

                EVENT_HANDLERS[event].push(handler);
        }

        /**
         * Unsubscribe from event
         * @method off
         * @param {string} event Event name
         * @param {function} handler Handler passed on Inffuse.on call
         * @example 
         *      Inffuse.on("data-changed",handle_data_change); 
         *      function handle_data_change(new_data)
         *      {
         *              if (new_data.hasOwnProperty("my_key"))
         *              {
         *                      // Do something once
         *
         *                      Inffuse.off("data-changed",handle_data_change);
         *                      return;
         *              }
         *      }); 
         */
        this.off = function(event,handler)
        {
                if (typeof EVENT_HANDLERS[event] == 'undefined')
                        return;

                EVENT_HANDLERS[event] = EVENT_HANDLERS[event].filter(function(h){
                        return h != handler;
                });
        }

        /**
         * @private
         */
        this.trigger = function(event,data)
        {
                var handlers = EVENT_HANDLERS[event];
                if (typeof handlers == 'undefined' || handlers.length == 0)
                        return false;

                EVENT_HANDLERS[event].map(function(handler){
                        handler(data);
                });

                return true;
        }

        /*------------------------------------------*/

        /**
         * Broadcast an event
         * @method broadcast
         * @param {string} event Event type
         * @param {object} data Event data
         * @param {object} includeSelf=false Should the event be sent to the current window.
         * @example  
         *      Inffuse.broadcast("my_custom_event",{weight: 78; height: 180; alias: "Johnny"});
         *
         *      Inffuse.on("my_custom_event",function(user){
         *              console.log("User Alias=%s, User Height=%d", user.alias, user.height)
         *      });
         */
        this.broadcast = function(event,data,includeSelf)
        {
                if (typeof window == 'undefined' || !window.parent || !window.parent.postMessage)
                        return;

                var msg = {
                                app: 'inffuse',
                                user: self.user ? self.user.key() : null,
                                //site: Inffuse.site ? Inffuse.site.key() : null,
                                project: self.project ? self.project.key() : null,
                                type: event,
                                params: data
                        }

                window.parent.postMessage(msg, "*");

                for (var i=0; i < window.frames.length; i++) {
			window.frames[i].postMessage(msg, "*");
		}

                if (includeSelf)
                        self.trigger(event,data);
        }

        /*------------------------------------------*/
        // undocumented
        this.loadScript = function(script_src,callback)
        {
                if (script_src.indexOf('//') == -1) {
                        var base_url = self.server;
                        script_src = base_url + script_src;
                }

                var head = document.getElementsByTagName('head')[0];
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = script_src;
                head.appendChild(script);

                script.onload = function(){
                        callback();
                };
        }

        /*------------------------------------------*/

        this.loadSite = function(site_id)
        {
                var params = {
                        user: self.user.id()
                };

                var deferred = new jQuery.Deferred();
                self.requestAPI('sites/'+site_id,params,'GET')
                        .then(
                                function(response){
                                        var site = new self.SiteClass(self,response.site);
                                        deferred.resolve(site);
                                },
                                function(err){
                                        deferred.reject(err);
                                });

                return deferred.promise();
        }

        /*------------------------------------------*/

        this.requestAPI = function(action,params,method,withCredentials,sync)
        {
                if (typeof method == 'undefined')
                        method = 'GET';

                params = params || {};
                
                params['app'] = self.app.id();
                params['platform'] = self.platform;

                if (this.demo_mode)
                // return empty resolved deferred object
                        return new function(){ this.success = function(callback){ callback(); }}
                
                var access_token = self.user.accessToken()
                if (access_token) {
                        params['access_token'] = access_token;
                }

                var url = [self.server,'api',self.apiVersion,action].join('/');
                if (method == 'GET' || method == 'DELETE') {
                        url += '?' + self.utils.buildQuery(params);
                        params = undefined;
                }

                var ajax_params = {
                        url: url,
                        type: method,
                        data: params,
                        async: !sync
                };

                if (withCredentials) {
                        ajax_params['crossDomain'] = true;
                        ajax_params['xhrFields'] = {withCredentials: true};
                }

                return self.utils.http(ajax_params);
        }

        /*------------------------------------------*/
        
        this.receiveMessage = function(message) 
        {
                switch (message.type)
                {
                        case 'data-changed':
                                if (self.project) {
                                        var project_data = message.params;
                                        for (let key in project_data)
                                                self.project.set(key,project_data[key],true);
                                }
                                
                                break;
                }

                self.trigger(message.type,message.params);
        }

        if (typeof window != 'undefined') // we're in browser 
        {
                window.addEventListener("message", function(event){
                        var message = event.data;
                        if (message.app != 'inffuse')
                                return;
                        
                        self.receiveMessage(message);
        
                }, false);
        }
}




//      InffuseSDK.prototype.Services = InffuseServices;
if (typeof Inffuse != 'undefined' && Inffuse.registerVersion)
        Inffuse.registerVersion("0.1",InffuseSDK_01);

if (typeof exports != 'undefined') {
        exports.InffuseSDK_01 = InffuseSDK_01;
}

        InffuseSDK_01.prototype.App = function(inffuse,meta, data)
        {
                var self = this;
                                
                this.init = function()
                {
                }
                
                /*------------------------------------------*/
                
                this.id = function()
                {
                        return meta.id;
                }
                
                this.platform = function()
                {
                        return meta.platform;
                }
                
                this.name = function()
                {
                        return meta.name;
                }
                
                /*------------------------------------------*/
                
                this.set = function(key,value,save)
                {
                        data[key] = value;

                        if (typeof save == 'undefined' || save)
                                this.save();
                }
                
                this.get = function(key,default_value)
                {
                        if (typeof key == 'undefined') // TODO: should this be supported?
                                return data;

                        if (key in data == false)
                                return default_value;
                        
                        return data[key];
                }
                
                this.remove = function(key,save)
                {
                        delete data[key];

                        if (typeof save == 'undefined' || save)
                                this.save();
                }
                
                /*------------------------------------------*/
        };


/**
* Inffuse.DataStore
* @class 
*/

InffuseSDK_01.prototype.DataStore = function(inffuse_instance, entity, entityID, scope)
{
        var self = this;
        var inBatch = false;
        var data = {};
        var Inffuse = inffuse_instance;

        this.populate = function(in_data)
        {
                data = in_data;
        }


        /**
         * Set the <value> under <key> entry in the project data.
         * @method Inffuse.project.set
         * @param {string} key Key to the data item.
         * @param {string} value The value to be set.
         * @param {boolean} in_batch=false Choose whether this is part of a batch operation. If set to true, the data will not be saved in the cloud, and save() will need to be called later.
         */

        this.set = function(key,value,in_batch)
        {

                data[key] = value;

                // if this is part of a batch change - don't send to the server.
                // the developer is responsible for calling project.save() at the end.
                if (in_batch)
                        return;

//              var real_scope = scope;

                var url = [entity,entityID,'data',scope,key].join('/');
                var params = {
                        user: Inffuse.user.id(),
                        value: JSON.stringify(value)
                };

                return Inffuse.requestAPI(url,params,'PUT')
                        .then(function(result){
                                var broadcast_params = {};
                                broadcast_params[key] = value;
                                
                                Inffuse.broadcast('data-changed',broadcast_params);
                        });
        }
        

        /**
         * Get the value stored under the key in the project data.
         * @method Inffuse.project.get
         * @param {string} key Key to the data item.
         * @param {string} default_value The default value to return, if <key> is not found.
         * @returns Value for <key> or default_value
         */
        this.get = function(key,default_value)
        {

                if (typeof key == 'undefined') // TODO: should this be supported?
                        return data;

                if (key in data == false)
                        return default_value;
                
                return data[key];
        }



        /**
         * Get the value stored under the key on the server.
         * @method Inffuse.project.loadData
         * @param {string} key Key to the data item.
         * @param {string} default_value The default value to return, if <key> is not found.
         * @returns Value for <key> or default_value
         */
        this.loadData = function(key, default_value)
        {
                var params = {
                        user: Inffuse.user.id(),
                };
                
                if(key)
                {
                        var url = [entity, entityID, 'data',scope, key].join('/');
                        return Inffuse.requestAPI(url,params,'GET')
                                .then(function(result){
                                        data[key] = result.data;
        //                              var params_data =  {};
        //                              params_data[key] = data[key];
                                        
        //                              Inffuse.broadcast('data-changed',params_data);
                                        return data[key];
                                });
                }
                else
                {
                        var url = [entity, entityID, 'data',scope].join('/');
                        return Inffuse.requestAPI(url,params,'GET')
                                .then(function(result){
                                        data= result.data;
        //                              var params_data =  {};
        //                              params_data[key] = data[key];
                                        
        //                              Inffuse.broadcast('data-changed',params_data);

                                        return data;
                                });
                }
        }


        this.append = function(key,value)
        {

                if (data[key] === undefined)
                        data[key] = [];
                
                data[key].push(value);


                var url = [entity,entityID,'data',scope,key].join('/');
                var params = {
                        user: Inffuse.user.id(),
                        value: JSON.stringify(value)
                };

                return Inffuse.requestAPI(url,params,'POST')
                        .then(function(result){
                                var params_data =  {};
                                console.log(key);
                                console.log(data);
                                params_data[key] = data[key];
                                
                                Inffuse.broadcast('data-changed',params_data);
                        });
        }

        this.del = function(key,save)
        {
                delete data[key];

                if (typeof save == 'undefined' || save)
                        self.save();
        }


        this.setData = function(project_data)
        {
                data = project_data;
        }

        this.setMulti = function(obj,in_batch)
        {
                // TODO: fix in_batch bug
                
                // if this is part of a batch change - don't send to the server.
                // the developer is responsible for calling project.save() at the end.
                for(var key in obj)
                {
                        if(obj.hasOwnProperty(key))
                        {
                                data[key] = obj[key]; 
                        }
                }

                if (in_batch)
                        return;

                var url = ['projects',entityID,'data',scope].join('/');
                var params = {
                        user: Inffuse.user.id(),
                        obj: JSON.stringify(obj)
                };

                return Inffuse.requestAPI(url,params,'POST')
                        .then(function(result){
                                Inffuse.broadcast('data-changed',obj);
                        });
        }

        this.save = function()
        {
                var url = [entity,entityID,'data',scope].join('/');
                var params = {
                        user: Inffuse.user.id(),
                        obj: JSON.stringify(data)
                };

                return Inffuse.requestAPI(url,params,'PUT')
                        .then(function(result){
                                Inffuse.broadcast('data-changed',data);
                        });
        }



}

//
// if the URL does not include http// , 
//
const ExternalScriptLoader = function(external_script)
{
        Inffuse.loadScript(external_script)
                .then(function(){
                        Inffuse.ready();
                });
        
}
/**
* Inffuse.ProjectClass
* @class 
*/

InffuseSDK_01.prototype.ProjectClass = function(inffuse_instance,meta,data,flags)
{
        var Inffuse = inffuse_instance;
        var self = this;
        var scope = Inffuse.user.accessToken() ? 'private' : 'public';

        var dataStores = {};
        var defaultDataStore;


        // Undocumented
        function init()
        {
                dataStores["private"] = new Inffuse.DataStore(Inffuse, 'projects', self.id(), 'private');
                dataStores["public"] = new Inffuse.DataStore(Inffuse, 'projects', self.id(),'public');
                defaultDataStore = dataStores[scope];

                defaultDataStore.populate(data);

                if (typeof document != 'undefined') { // browser
                        document.addEventListener('keyup', function(e) {
                                if (e.ctrlKey && e.altKey){
                                        // Ctrl-Alt-D-A to open the dashboard
                                        if (e.keyCode == 65 && self._last_key == 68)
                                                window.open(self.manage());

                                        self._last_key = e.keyCode;
                                }
                        }, false);
                }
        }
        
        /**
         * Get the dataStore for the default scope or the <scope_override> for the project
         * @method Inffuse.project.getDataStore
         * @param {string} scope_override if given, overrides the default scope. legal values are "private" or "public".
         */
        this.getDataStore = function(scope_override)
        {
                if (scope_override)
                        return dataStores[scope_override];
                
                return defaultDataStore;
        }
        
        // internal
        this.meta = function(key) {
                return meta ? meta[key] : null;
        }

        /**
         * Returns the current project ID
         * @method Inffuse.project.id
         * @returns {String}
         * @example
         *      var project_id = Innfuse.project.id()
         *      console.log("Project ID = %s", project_id);
         */
        this.id = function()
        {
                return self.meta('id');
        }

        /**
         * Returns the current project created
         * @method Inffuse.project.created
         * @returns {String}
         * @example
         *      var project_created = Innfuse.project.created()
         *      console.log("Project created = %s", project_created);
         */
        this.created = function()
        {
                return self.meta('created');
        }

        /**
         * Returns the value of a custom flag
         * @method Inffuse.project.flag
         * @returns {String}
         * @example
         *      var project_flag = Innfuse.project.flag('disabled')
         *      console.log("Project Enabled = %s", project_flag);
         */
        this.flag = function(key)
        {
                return flags && flags[key];
        }

        /**
         * Returns the current project key name
         * @method Inffuse.project.key
         * @returns {String}
         * @example
         *      var project_key = Innfuse.project.key()
         *      console.log("Project Key = %s", project_key);
         */
        this.key = function()
        {
                return self.meta('key_name');
        }

        /**
         * Returns the current project name
         * @method Inffuse.project.name
         * @returns {string}
         * @example
         *      var project_name = Innfuse.project.name()
         *      console.log("Project Name = %s", project_name);
         */
        this.name = function()
        {
                return self.meta('name');
        }

        /**
         * Returns true when the project is first created
         * @method Inffuse.project.isNew
         * @returns {boolean}
         * @example
         *      var is_new = Innfuse.project.isNew()
         *      console.log("Project Is %s", is_new ? "new":"not new");
         */
        this.isNew = function()
        {
                return meta['new'] == true;
        }
        
        /**
         * Returns the ID of the site which owns the project
         * @method Inffuse.project.siteID
         * @returns {string}
         * @example
         *      var site_id = Innfuse.project.siteID()
         *      console.log("The site ID of the project %s", site_id);
         */
        this.siteID = function()
        {
                return self.meta('site_id');
        }
        
        /**
         * Set the <value> under <key> entry in the project data.
         * @method Inffuse.project.set
         * @param {string} key Key to the data item.
         * @param {mixed} value The value to be set.
         * @param {boolean} in_batch=false Choose whether this is part of a batch operation. If set to true, the data will not be saved in the cloud, and Inffuse.project.save() will need to be called later.
         * @param {string} scope_overide if given, overrides the default scope. legal values are "private" or "public".
         * @returns {object} jQuery jqXHR object
         * @example Simple set
         *      Inffuse.project.set('name', 'John');     
         *      Inffuse.project.set('age', 32);  
         * @example Wait for response
         *      Inffuse.project.set('params', {
         *              weight: 78,
         *              height: 180, 
         *              alias: "Johnny"
         *      })
         *      .done(function(){
         *              // Success!
         *      })
         *      .fail(function(){
         *              // Handle set failure
         *      });
         */
        this.set = function(key,value,in_batch,scope_overide)
        {
                if (!this.id())
                        return Inffuse.error("Project does not exist");

                return this.getDataStore(scope_overide).set(key,value,in_batch);
        }

        /**
         * Get the value stored under the <key> in the project data.
         * @method Inffuse.project.get
         * @param {string} key Key to the data item.
         * @param {string} default_value The default value to return, if <key> is not found.
         * @returns {mixed} Value for <key>, if <key> not found returns the default_value. if no default_value supplied returns 'undefined'.
         * @example
         *      var name = Inffuse.project.get('name', null);    
         *      if (name)
         *      {
         *              //do something...
         *      }
         *      else
         *      {
         *              //key=name does not exsist...
         *      }
         */
        this.get = function(key,default_value)
        {
                if (!this.id())
                        return Inffuse.error("Project does not exist");
                
                return defaultDataStore.get(key,default_value);
        }


        /**
         * Get the value stored under the key on the server.
         * @method Inffuse.project.loadData
         * @param {string} key Key to the data item.
         * @param {string} scope_overide Defines if the value will be taken from "public" or "private" areas in the DB.
         * @param {string} default_value The default value to return, if <key> is not found.
         * @returns the XHR requestAPI call. 
         */
        this.loadData = function(key, scope_overide, default_value)
        {
                if (!this.id())
                        return Inffuse.error("Project does not exist");
                
                return this.getDataStore(scope_overide).loadData(key,default_value);
        }


        /**
         * Append the <value> to an array stored under <key>. If <key> is not set yet a new array will be created.
         * @method Inffuse.project.append
         * @param {string} key Key to the array.
         * @param {mixed} value The value to be appended.
         * @returns {object} jQuery jqXHR object
         * @example
         *      for(var i=0; i<10; i++)
         *              Inffuse.project.append("my_array", "item"+i)
         */
        this.append = function(key,value)
        {
                if (!this.id())
                        return Inffuse.error("Project does not exist");
                
                return defaultDataStore.append(key,value);
        }

        /**
         * Removes the key entry from project data.
         * @method Inffuse.project.del
         * @param {string} key Key to the data item.
         * @param {boolean} in_batch=false Choose whether this is part of a batch operation. If set to true, the data will not be deleted in the cloud, and Inffuse.project.save() will need to be called later.
         * @example
         *      Innfuse.project.del("my_key")
         *      console.log("my_key is deleted!");
         */
        this.del = function(key,save)
        {
                return defaultDataStore.del(key,save);
        }

        // Undocumented
        this.setData = function(project_data)
        {
                return defaultDataStore.setData(project_data);
        }


        /**
         * Set the all the values in <obj> under the matching keys in <obj> in the project data.
         * @method Inffuse.project.setMulti
         * @param {object} obj an object containing a list of key:value entries.
         * @param {boolean} in_batch=false Choose whether this is part of a batch operation. If set to true, the data will not be saved in the cloud, and Inffuse.project.save() will need to be called later.
         * @returns {object} jQuery jqXHR object
         * @example Simple setMulti
         *      Inffuse.project.setMulti({
         *              name: 'My Name', 
         *              age: 29, 
         *              my_list: ['item1', 'item2', item3],
         *              my_obj: {first: 1, second: 2, third: 999}
         *      });
         * @example Wait for response
         *      Inffuse.project.setMulti({
         *              name: 'My Name', 
         *              my_list: ['item1', 'item2', item3]
         *      })
         *      .done(function(){
         *              // Success!
         *      })
         *      .fail(function(){
         *              // Handle setMulti failure
         *      });
         */
        this.setMulti = function(obj,in_batch)
        {
                if (!this.id())
                        Inffuse.error("Project does not exist");

                return defaultDataStore.setMulti(obj,in_batch);
        }

        
        // Undocumented
        this.setToken = function(service_name,token_key,token_value)
        {
                var params = {
                        project: self.id(),
                        service: service_name,
                        key: token_key,
                        value: token_value
                };
                        
                var url = ['projects',self.id(),'settoken'].join('/');
                return Inffuse.requestAPI(url,params,'POST');
        }
        

        /**
         * Save all changes previusely made with in_batch=true to the cloud.
         * @method Inffuse.project.save
         * @returns {object} jQuery jqXHR object
         * @example
         *      Inffuse.project.set('name', 'John', true);       
         *      Inffuse.project.set('age', 32, true);    
         *      Inffuse.project.set('params', {
         *              weight: 78, 
         *              height: 180,
         *              alias: "Johnny"
         *      }, true);
         *      
         *      Inffuse.project.save()
         *              .done(function(){
         *                      // Success
         *              })
         *              .fail(function(){
         *                      // Handle batch save failure
         *              });
         */
        this.save = function()
        {
                if (!this.id())
                        Inffuse.error("Project does not exist");

                return defaultDataStore.save()
        }

        
        /**
         * Publish the current project data.
         * @method Inffuse.project.publish
         * @returns {object} jQuery jqXHR object
         * @example
         *      Inffuse.project.publish()
         *              .done(function(){
         *                      console.log("Project Data Published OK");
         *              })
         *              .fail(function(){
         *                      console.error("Error publishing Project Data!");                
         *              });
         */
        this.publish = function()
        {
                Inffuse.broadcast('project-published',undefined,true);

                var url = ['projects',self.id(),'data','publish'].join('/');
                var params = {
                        user: Inffuse.user.id(),
                        project: self.id()
                };
                
                return Inffuse.requestAPI(url,params,'POST');
        }

                
        /**
         * Delete the project from the system
         * @method Inffuse.project.remove
         * @returns {object} jQuery jqXHR object
         * @example
         *      Inffuse.project.remove()
         *              .done(function(){
         *                      console.log("Project was deleted");
         *              })
         *              .fail(function(){
         *                      console.error("Error deleting this project");           
         *              });
         */
        this.remove = function()
        {
                Inffuse.broadcast('project-deleted',undefined,true);

                var url = ['projects',self.id()].join('/');
                var params = {
                        user: Inffuse.user.id()
                }

                // sync is needed to prevent the widget to be deleted
                // and the request be cancelled
                return Inffuse.requestAPI(url,params,'DELETE',false,true);
        }
        

        /**
         * Update the project
         * @method Inffuse.project.update
         * @returns {object} jQuery jqXHR object
         * @example
         *      Inffuse.project.remove()
         *              .done(function(){
         *                      console.log("Project was deleted");
         *              })
         *              .fail(function(){
         *                      console.error("Error deleting this project");           
         *              });
         */
        this.update = function(params)
        {
                params['user'] = Inffuse.user.id();

                var url = ['projects',self.id()].join('/');
                return Inffuse.requestAPI(url,params,'POST');
        }


        // Undocumented
        this.refreshWidget = function(params)
        {
                Inffuse.broadcast('refresh-widget',params);
        }

        
        /**
         * Request the widget height to be updated
         * @method Inffuse.project.updateHeight
         * @param {integer} height The new height. If nothing is passed - the widget height will be updated to the body height.
         * @example
         *      var height = $('#content') + 20;
         *      Inffuse.project.updateHeight(height)
         */
        this.updateHeight = function(height)
        {
                if (typeof height == 'undefined')
                {
                        var container = document.getElementsByClassName('inffuse-container');
                        if (container.length)
                                container = container[0];
                        else
                                container = document.body;

                        height = container.offsetHeight;
                }

                self.resize({height:height});
        }


        // Undocumented
        this.resize = function(params)
        {
                if (params.height && Inffuse.platform == 'wix' && typeof Wix != 'undefined')
                        Wix.setHeight(params.height);
                
                Inffuse.broadcast('resize',params);
        }

        // Undocumented
        this.preview = function(params)
        {
                self.refreshWidget(params);
        }

        /*------------------------------------------*/

        /**
         * Helper function to display a direct link to the users account in Inffuse dashboard.
         * The link will be printed using console.log().
         * @method Inffuse.project.manage
         */
        this.manage = function() {
                var host = Inffuse.server.indexOf("local") == -1 ? "dashboard.inffuse.com" : "dev.inffuse.local:28099";
                var url = [
                        "http:/",
                        host,
                        "app:"+Inffuse.app.id(),
                        "users",
                        "project:"+self.id()
                ].join('/');

                console.log('To manage the project go to: ',url);
                return url;
        }

        /*------------------------------------------*/

        init();
};

InffuseSDK_01.prototype.ProjectsClass = function(inffuse_instance)
{
        var Inffuse = inffuse_instance;

        /*------------------------------------------*/

        this._init = function()
        {
        }
        
        /*------------------------------------------*/

        this.list = function()
        {
                var params = {
                        user: Inffuse.user.id(),
                };

                return Inffuse.requestAPI('projects',params,'GET');
        }

        this.create = function(name)
        {
                var params = {
                        user: Inffuse.user.id(),
                        site: (Inffuse.site && Inffuse.site.id()) ? Inffuse.site.id() : null,
                        name: name
                };

                return Inffuse.requestAPI('projects',params,'POST');
        }

        this.load = function(project_id,expand_site)
        {
                var expand_list = [];
                if (expand_site)
                        expand_list.push('site');

                var params = {
                        user: Inffuse.user.id(),
                        fields: 'data',
                        expand: expand_list.join(',')
                };

                var deferred = new jQuery.Deferred();
                Inffuse.requestAPI('projects/'+project_id,params,'GET')
                        .then(
                                function(project_details){
                                        var project = new Inffuse.ProjectClass(Inffuse,project_details.project,project_details.data);
                                        deferred.resolve(project);
                                },
                                function(err){
                                        deferred.reject(err);
                                });

                return deferred.promise();
        }
};

InffuseSDK_01.prototype.Quotas = function(inffuse_instance, quotas)
{
        var quotas = quotas;
        var Inffuse = inffuse_instance;
        
        this.get = function(id)
        {
                if (typeof quotas[id] == 'undefined')
                        return -1;

                return quotas[id];
        }

        this.consume = function(id,count)
        {
                params = {};
                if (typeof count == 'undefined')
                        count = 1;
                
                params['count'] = count;

                var quota = quotas[id];
                if (typeof quota == 'undefined')
                        return false;

                switch (quota.scope)
                {
                        case 'user':
                                params['user'] = Inffuse.user.id();
                                break;

                        case 'site':
                                params['site'] = Inffuse.site.id();
                                break;

                        case 'project':
                                params['project'] = Inffuse.project.id();
                                break;
                }


                return Inffuse.requestAPI('quotas/'+id+'/consume',params,'POST')
                        .then(function(data){
                                quota.remaining = data.remaining;
                        });
        }
};

InffuseSDK_01.prototype.Redirect = function(url)
{
        this.url = url;

        //var debug_mode = true;
        if (typeof debug_mode == 'undefined' || confirm("Redirecting to " + this.url))
                document.location.href = this.url;
        
        // reduce the time the page is visible on redirect
        document.getElementsByTagName("html")[0].style.visibility = 'hidden';
}
/**
* @namespace
* @memberof Inffuse
*/

InffuseSDK_01.prototype.Services = function(inffuse_instance,services)
{
        var Inffuse = inffuse_instance;
        var self = this;

        function addServiceMethod(obj,service_name,method_name,func) {
                var path = [service_name,method_name].join('.');
                var parts = path.split('.');

                for (var i=0; i < parts.length-1; i++)
                {
                        var part = parts[i];

                        if (typeof obj[part] == 'undefined')
                                obj[part] = {};

                        obj = obj[part];
                }

                var last = parts.pop();
                obj[last] = func;
        }

        /* creating all supported services */
        for (var iService = 0; iService < services.length; iService++)
        {
                var service = services[iService];

                for (var iMethod = 0; iMethod < service.methods.length; iMethod++)
                {
                        var method = service.methods[iMethod];

                        (function(service,method) {
                                var method_func = function(){
                                
                                        var args = {};
                                        for (var i = 0; i < method.args.length; i++) {
                                                var name = method.args[i];
                                                var value = arguments[i];

                                                args[name] = value;
                                        }

                                        var _service = service.name.replace(/\./g,'/');
                                        var _method = method.name.replace(/\./g,'/');
                                        
                                        var _params = {
                                                method: [_service,method.name].join('.'), // for possible error message
                                                path: ['services',_service,_method].join('/'),
                                                type: method.type // default:"GET"
                                        }

                                        _params['args'] = {};
                                        for (var name in args) {
                                                var value = args[name];
                                                if (typeof value == 'function')
                                                        continue;

                                                if (typeof value == 'object')
                                                        value = JSON.stringify(value)

                                                _params['args'][name] = value;
                                        }

                                        _params.args['platform'] = Inffuse.platform;
                                        _params.args['app'] = Inffuse.app.id();

                                        if (Inffuse.user) {
                                                _params.args['user'] = Inffuse.user.id();
                                                _params.args['site'] = Inffuse.site ? Inffuse.site.id() : null;
                                                _params.args['project'] = Inffuse.project ? Inffuse.project.id() : null;
                                        }
                                        
                                        if (method.args.indexOf("callback") != -1)
                                                _params['callback'] = args.callback;
                                        
                                        return Inffuse.services[method.action || 'fetch'](_params);
                                };

                                addServiceMethod(self, service.name, method.name, method_func);

                        })(service,method);
                }
        }

        this.fetch = function(params)
        {
                return Inffuse.requestAPI(params.path,params.args,params.type)
                        .fail(function(data){
                                if (data.responseJSON && data.responseJSON.error)
                                        Inffuse.error(['Request to',params.method,'failed','('+data.responseJSON.error+')'].join(' '));
                        });
        }

        this.openWindow = function(params)
        {
                function onMessage(event)
                {
                        var data = event.data;
                        if (data.app != 'inffuse')
                                return;

                        if (data.type == 'connected')
                        {
                                //console.log("received message",event);
                                window.removeEventListener("message",onMessage);

                                params.callback(data.params);
                        }
                }

                // session id will be used for polling key on IE
                var session_id = Math.floor(Math.random()*100000000);
                params.args['session'] = session_id;

                var url = [Inffuse.server,'api',Inffuse.apiVersion,params.path].join('/');
                url += '?'+Inffuse.utils.buildQuery(params.args);

                var popup = window.open(url,"Inffuse","height=600,width=600");

                var IEbrowser = "ActiveXObject" in window;
                if (!IEbrowser)
                        window.addEventListener('message', onMessage, false);
                else {
                        var interval = setInterval(function(){
                                Inffuse.requestAPI('async/get',{session: session_id},'GET')
                                        .then(function(response){
                                                if (!response.result)
                                                        return;

                                                clearInterval(interval);
                                                params.callback(response.data);
                                        })
                        },1000);
                }
        }
};

InffuseSDK_01.prototype.Site =  function(inffuse_instance,meta)
{
        var Inffuse = inffuse_instance;
        var self = this;
        var meta = meta;
        
        /*------------------------------------------*/

        /**
         * Returns the current site ID
         * @method Inffuse.site.id
         * @returns {string}
         */
        this.id = function()
        {
                return meta ? meta.id : null;
        }

        /**
         * Returns the current site created
         * @method Inffuse.site.created
         * @returns {string}
         */
        this.created = function()
        {
                return meta ? meta.created : null;
        }

        /**
         * Returns the current site key name
         * @method Inffuse.site.key
         * @returns {String}
         */
        this.key = function()
        {
                return meta ? meta.key_name : null;
        }
        
        /*------------------------------------------*/

        this.meta = function(callback) {
                Inffuse.error('Inffuse.site.meta() method is not implemented on '+Inffuse.platform+' platform');
                callback(null);
        }

        this.pages = function(callback) {
                Inffuse.error('Inffuse.site.pages() method is not implemented on '+Inffuse.platform+' platform');
        }

        this.currentPage = function(callback) {
                Inffuse.error('Inffuse.site.currentPage() method is not implemented on '+Inffuse.platform+' platform');
        }
        
        /*------------------------------------------*/
        
        this.listProjects = function()
        {
                var params = {
                        user: Inffuse.user.id(),
                        site: Inffuse.site.id(),
                };

                return Inffuse.requestAPI('projects',params,'GET');
        }

        this.createProject = function(name)
        {
                var params = {
                        user: Inffuse.user.id(),
                        site: Inffuse.site.id(),
                        name: name
                };

                return Inffuse.requestAPI('projects',params,'POST');
        }

        /*------------------------------------------*/

        /**
         * Helper function to display a direct link to the users account in Inffuse dashboard.
         * The link will be printed using console.log().
         * @method Inffuse.project.manage
         */
        this.manage = function() {
                var host = Inffuse.server.indexOf("local") == -1 ? "dashboard.inffuse.com" : "dev.inffuse.local:28099";
                var url = [
                        "http:/",
                        host,
                        "app:"+Inffuse.app.id(),
                        "users",
                        "site:"+self.id()
                ].join('/');

                console.log('To manage the site go to: ',url);
        }

        /*------------------------------------------*/
};

InffuseSDK_01.prototype.UI = function(inffuse_instance)
{
        var Inffuse = inffuse_instance;
        var thisObj = this;
        
        this.init = function()
        {
                $('[data-section-title]').click(function(){
                                thisObj.toggleSection($(this).parent());
                        });
        }
        
        this.toggleSection = function(section)
        {
                var wasOpen = section.hasClass('open');
                                
                $('[data-section]').removeClass('open');
                $('[data-section]').addClass('closed');
                
                if (wasOpen)
                {
                        section.removeClass('open');
                        section.addClass('closed');
                        
                } else
                {
                        section.addClass('open');
                        section.removeClass('closed');
                }

                // Why? Inffuse.project.updateHeight();
        }
        
        this.openColorSelect = function(colorpicker,current,callback)
        {
                if (colorpicker.find('.colorpopup').length) // a colorpicker is already open
                        return;

                function updateHex(rgb)
                {
                        var hex = colorpicker.find('input.hex');
                        hex.val(Inffuse.ui.rgbToHex(rgb));
                }
                
                function update(event)
                {
                        var el = $(event.target);
                        if (el.hasClass('colorcell'))
                        {
                                colorpicker.find('.current').removeClass('current');
                                el.addClass('current');

                                updateHex(el.attr('data-color'));
                                
                                submit();
                        }
                        
                        event.preventDefault();
                        return false;
                }
                
                function close(event)
                {
                        colorpicker.find('.colorpopup').remove();
                        $('body').unbind('click',close);
                        
                        if (event)
                                event.preventDefault();
                }
                
                function submit(event)
                {
                        //Inffuse.analytics.track('Color Changed');

                        var color = colorpicker.find('.hex').val();
                        color = Inffuse.ui.hexToRgb(color);
                        
                        // todo - leave the popup open and show error
                        if (color)
                        {
                                callback(color);
                        }
                        
                        close();
                }
                
                var colors_google = [
                                //["rgb(0,0,0)", "rgb(68,68,68)", "rgb(102,102,102)", "rgb(153,153,153)", "rgb(204,204,204)", "rgb(238,238,238)", "rgb(243,243,243)", "rgb(255,255,255)", ""], 
                                ["","rgb(0,0,0)", "rgb(77,77,77)", "rgb(115,115,115)", "rgb(171,171,171)", "rgb(224,224,224)", "rgb(242,242,242)", "rgb(255,255,255)"],
                                ["rgb(255,0,0)", "rgb(255,153,0)", "rgb(255,255,0)", "rgb(0,255,0)", "rgb(0,255,255)", "rgb(0,0,255)", "rgb(153,0,255)", "rgb(255,0,255)"], 
                                ["rgb(244,204,204)", "rgb(252,229,205)", "rgb(255,242,204)", "rgb(217,234,211)", "rgb(208,224,227)", "rgb(207,226,243)", "rgb(217,210,233)", "rgb(234,209,220)"], 
                                ["rgb(234,153,153)", "rgb(249,203,156)", "rgb(255,229,153)", "rgb(182,215,168)", "rgb(162,196,201)", "rgb(159,197,232)", "rgb(180,167,214)", "rgb(213,166,189)"], 
                                ["rgb(224,102,102)", "rgb(246,178,107)", "rgb(255,217,102)", "rgb(147,196,125)", "rgb(118,165,175)", "rgb(111,168,220)", "rgb(142,124,195)", "rgb(194,123,160)"], 
                                ["rgb(204,0,0)", "rgb(230,145,56)", "rgb(241,194,50)", "rgb(106,168,79)", "rgb(69,129,142)", "rgb(61,133,198)", "rgb(103,78,167)", "rgb(166,77,121)"], 
                                ["rgb(153,0,0)", "rgb(180,95,6)", "rgb(191,144,0)", "rgb(56,118,29)", "rgb(19,79,92)", "rgb(11,83,148)", "rgb(53,28,117)", "rgb(116,27,71)"], 
                                ["rgb(102,0,0)", "rgb(120,63,4)", "rgb(127,96,0)", "rgb(39,78,19)", "rgb(12,52,61)", "rgb(7,55,99)", "rgb(32,18,77)", "rgb(76,17,48)"]
                        ];
                
                var colors = [
                                ["rgb(0,0,0)", "rgb(153,51,0)", "rgb(51,51,0)", "rgb(0,0,128)", "rgb(51,51,153)", "rgb(51,51,51)"],
                                ["rgb(128,0,0)", "rgb(255,102,0)", "rgb(128,128,0)", "rgb(0,128,0)", "rgb(0,128,128)", "rgb(0,0,255)"],
                                ["rgb(102,102,153)", "rgb(128,128,128)", "rgb(255,0,0)", "rgb(255,153,0)", "rgb(153,204,0)", "rgb(51,153,102)"],
                                ["rgb(51,204,204)", "rgb(51,102,255)", "rgb(128,0,128)", "rgb(153,153,153)", "rgb(255,0,255)", "rgb(255,204,0)"], 
                                ["rgb(255,255,0)", "rgb(0,255,0)", "rgb(0,255,255)", "rgb(0,204,255)", "rgb(153,51,102)", "rgb(192,192,192)"],
                                ["rgb(255,153,204)", "rgb(255,204,153)", "rgb(255,255,153)", "rgb(204,255,255)", "rgb(153,204,255)", "rgb(255,255,255)"]
                        ];

                var popup = $('<div class="colorpopup"></div>');
                for (var row in colors)
                {
                        var rowElement = $('<div class="colorsrow"></div>');
                        for (var i in colors[row])
                        {
                                var color = colors[row][i];
                                
                                var bg = (color == "") ? "url(/static/wix/img/none.png)" : color;
                                var cell = $('<div class="colorcell" data-color="'+color+'" style="background: '+bg+'"></div>');

                                if (color == current)
                                        cell.addClass('current');
                                        
                                rowElement.append(cell);
                                
                                cell.click(update);
                        }
                        
                        popup.append(rowElement);
                }
                
                var hex = $('<div class="colorhex"><span class="labelhex">hex:</span> <input class="hex" type="text" /> <div class="clear"></div></div>');
                hex.find('input').change(update);
                popup.append(hex);

                var button = $('<button class="ok">OK</button>');
                button.click(submit).append('<div class="clear"></div>');
                popup.append(button);
                
                colorpicker.append(popup);
                if (popup.offset().top+popup.height() > $('body').height())
                        popup.addClass('top');
                        
                setTimeout(function(){
                                updateHex(current);
                                
                                $('body').bind('click',close);
                                $(popup).click(function(){
                                                return false;
                                        });
                        },0);
        }
        
        this.rgbToHex = function(rgb)
        {
                if (typeof rgb == 'undefined')
                        return '';

                function tohex(d)
                {
                        var hex = (1*d).toString(16);
                        return hex.length == 1 ? "0" + hex : hex;
                }
                
                rgb = rgb.substr(4,rgb.length-5);
                return '#' + rgb.split(',').map(tohex).join('');
        }
        
        this.hexToRgb = function(hex)
        {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                if (!result) // try also the short form
                        result = /^#?([a-f\d])([a-f\d])([a-f\d])$/i.exec(hex);
                
                if (!result)
                        return null;
                        
                result.shift();
                
                var values = result.map(function(h){
                                if (h.length == 1)
                                        h = h+h;
                                        
                                return parseInt(h,16)
                        });

                return 'rgb('+values.join(',')+')';
        }
        
        this.addCssRule = function(selector,rule)
        {
                // http://stackoverflow.com/questions/311052/setting-css-pseudo-class-rules-from-javascript
                
                rule = rule + " !important";

                if (document.styleSheets[1].insertRule)
                        document.styleSheets[1].insertRule(selector + ' {' + rule +' }', 0);
                else if (document.styleSheets[1].addRule)
                        document.styleSheets[1].addRule(selector, rule, 0);
        }

        this.opacity = function(color,opacity)
        {
                if (!color)
                        return color;

                if (color[0] == '#') // hex based color
                {
                        color = color.substr(1);
                        shorthand = (color.length == 3);
                        
                        if (shorthand)
                                colors = [color[0]+color[0], color[1]+color[1], color[2]+color[2]];
                        else
                                colors = [color.substr(0,2), color.substr(2,2), color.substr(4,2)];

                        colors = colors.map(function(s){ return parseInt(s,16); });
                        colors.push(opacity);
                        color = 'rgba(' + colors.join(',') + ')';
                
                } else if (color.substr(0,3) == 'rgb') {
                        color = color.replace('rgb(','')
                        color = color.replace('rgba(','')
                        color = color.replace(')','')
                        color = color.replace(' ','')
                        colors = color.split(',').slice(0,3);

                        colors.push(opacity);
                        color = 'rgba(' + colors.join(',') + ')';

                } else if (color.substr(0,3) == 'hsl') {
                        color = color.replace('hsl(','')
                        color = color.replace('hsla(','')
                        color = color.replace(')','')
                        color = color.replace(' ','')
                        colors = color.split(',').slice(0,3);

                        colors.push(opacity);
                        color = 'hsla(' + colors.join(',') + ')';
                }

                return color;
        }

        this.openModal = function(params)
        {
                Inffuse.ui.alert("Inffuse error :: openModal is not supported on " + Inffuse.platform + " platform");
        }

        this.closeModal = function()
        {
                Inffuse.ui.alert("Inffuse error :: closeModal is not supported on " + Inffuse.platform + " platform");
        }

        this.alert = function(str)
        {
                alert(str);
        }
};

InffuseSDK_01.prototype.Utils = function(inffuse_instance)
{
        var Inffuse = inffuse_instance;
        
        this.init = function()
        {
        }
        
        this.openPopup = function(params)
        {
                function onClose() {
                        Inffuse.off('popup-closed',onClose);
                        if (params.onClose)
                                params.onClose();
                }
                
                var msg_params = {};
                var keys = Object.keys(params);
                for (var i=0; i < keys.length; i++) {
                        var key = keys[i];
                        if (typeof params[key] == 'function')
                                continue;

                        msg_params[key] = params[key]
                }
                
                Inffuse.broadcast('open-popup',msg_params);
                Inffuse.on('popup-closed',onClose);
        }

        this.closePopup = function()
        {
                Inffuse.broadcast('close-popup');
        }

        this.buildQuery = function(params) {
                if (!params)
                        return '';

                return Object.keys(params).map(function(k) {
                        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
                }).join('&');
        }

        this.http = function(params) {
                var q = Inffuse.http({
                        method: params.type,
                        url: params.url,
                        headers: params.header,
                        data: params.data,
                        xhrFields: params.xhrFields
                
                })
                
                if (params.success) {
                        q.then(function(result) {
                                params.success && params.success(result);
                        });
                }

                if (params.error) {
                        (q['catch'] || q['fail'])(function(error) { // catch breaks the js minifier, fix by changing to ['catch']
                                var status = typeof error.status != 'undefined' ? error.status : error.response.status;
                                var data = typeof error.responseText != 'undefined' ? error.responseText : (error.response || {}).data;
                                params.error && params.error(status,data);
                        });
                }

                return q;
        }
};
/**
* Inffuse.UserClass
* @class 
*/

InffuseSDK_01.prototype.UserClass = function(inffuse_instance, meta, data)
{
        var Inffuse = inffuse_instance
        var self = this;

        this.init = function()
        {
        }
        
        // internal
        this.meta = function(key) {
                return meta ? meta[key] : null;
        }
        
        /*------------------------------------------*/

        /**
         * Create a new user
         * @method Inffuse.user.create
         * @param {string} name The name of the user
         * @param {string} email The email of the user
         * @param {string} password The password of the user
         * @returns {object} jQuery jqXHR object
         */
        this.create = function(name,email,password,captcha_response)
        {
                var params = {
                        name: name,
                        email: email,
                        password: password,
                        captcha: captcha_response /* optional */
                };

                return Inffuse.requestAPI('users',params,'POST',true)
                        .then(function(data){
                                meta = data.user;
                        });
        }

        /**
         * Autorize user
         * @method Inffuse.user.login
         * @param {string} email The email of the user
         * @param {string} password The password of the user
         * @returns {object} jQuery jqXHR object
         */
        this.login = function(email,password)
        {
                var params = {
                        email: email,
                        password: password
                };

                var retvalue = Inffuse.requestAPI('users/login',params,'POST',true)
                        .then(function(data){
                                meta = data.user;
                        });

                return retvalue;
        }

        /**
         * Log-out the current user
         * @method Inffuse.user.logout
         * @returns {object} jQuery jqXHR object
         */
        this.logout = function()
        {
                return Inffuse.requestAPI('users/logout',{},'POST',true)
                        .then(function(data){
                                meta = {};
                        });
        }

        /**
         * Returns whether the current user is logged in
         * @method Inffuse.user.loggedin
         * @returns {boolean}
         */
        this.loggedin = function()
        {
                return !!this.id();
        }

        /**
         * Requests an email with password reset instructions
         * @method Inffuse.user.resetPasswordRequest
         * @param {string} email The email of the account for which the reset password is being requested
         * @returns {object} jQuery jqXHR object
         */     
        this.resetPasswordRequest = function(email)
        {
                return Inffuse.requestAPI('users/reset_password_request',{email:email},'POST');
        }

        /**
         * Changes the password using a reset token issues previously via reset email
         * @method Inffuse.user.resetPassword
         * @param {string} user_id The user ID of the requester
         * @param {string} password The new password
         * @param {string} token The authorization token included in the reset request email 
         * @returns {object} jQuery jqXHR object
         */
        this.resetPassword = function(user_id,password,token)
        {
                return Inffuse.requestAPI('users/reset_password',{user:user_id,password:password,token:token},'POST');
        }

        /*------------------------------------------*/
        
        /**
         * Returns the current user ID
         * @method Inffuse.user.id
         * @returns {string}
         * @example
         *      var user_id = Inffuse.user.id()
         *      console.log("User ID = %s", user_id);
         */
        this.id = function()
        {
                return meta.id;
        }

        /**
         * Returns the current user created
         * @method Inffuse.user.created
         * @returns {string}
         * @example
         *      var user_created = Inffuse.user.created()
         *      console.log("User created = %s", user_created);
         */
        this.created = function()
        {
                return meta.created;
        }

        /**
         * Returns the current user key name
         * @method Inffuse.user.key
         * @returns {string}
         * @example
         *      var user_key = Inffuse.user.key()
         *      console.log("User Key = %s", user_key);
         */
        this.key = function()
        {
                return meta.key_name;
        }

        /**

         * Returns the current user plan
         * @method Inffuse.user.plan
         * @returns {string} User plan, or "free"
         * @example
         *      var user_plan = Inffuse.user.plan()
         *      console.log("User plan = %s", user_plan);
         */
        // TODO: return empty when free?
        this.plan = function()
        {
                return meta.plan;
        }
        
        /**
         * Returns true when the user is first created
         * @method Inffuse.user.isNew
         * @returns {boolean}
         * @example
         *      if (Inffuse.user.isNew())
         *              console.log("Hello new user...");
         */
        this.isNew = function()
        {
                return meta['new'] == true;
        }

        /**
         * Update the user object
         * @method Inffuse.user.update
         * @returns {object} jQuery jqXHR object
         * @example
         *      Inffuse.user.update(user_object)
         */
        this.update = function(user)
        {
                user['user'] = self.id();
                return Inffuse.requestAPI('users/'+self.id(),user,'POST')
                        .then(function(data){
                                for (var key in user) {
                                        if (key != 'password')
                                                meta[key] = user[key];
                                }
                        });
        }
                
        /**
         * Editor mode only! (Set available only from settings window)
         * Get or Set the user email
         * Returns the current user email if no <email> parameter supplied (Get).
         * @method Inffuse.user.email
         * @returns {string} User email (Get)
         * @returns {object} jQuery jqXHR object (Set).
         * @example
         *      user_email = Inffuse.user.email()
         *      console.log("User email=%s", user_email);
         * @example Wait for response
         *      Inffuse.user.email('newmail@domain.com')
         *              .done(function(){
         *                      // Success!
         *              })
         *              .fail(function(){
         *                      // Handle set email failure
         *              });
         */
        // TODO: leave always available and secure via permissions?
        this.email = function(email)
        {
                if (typeof email == 'undefined')
                        return meta.email;
                
                meta['email'] = email;

                var params = {
                        user: self.id(),
                        email: email
                };
                        
                return Inffuse.requestAPI('users/update',params,'POST')
        }

        /**
         * Editor mode only! (Set available only from settings window)
         * Get or Set the user name
         * Returns the current user name if no <name> parameter supplied (Get).
         * @returns {object} jQuery jqXHR object if <name> parameter supplied (Set). Editor mode only option! (Available only from settings window)
         * @method Inffuse.user.name
         * @returns {string} User name (Get)
         * @returns {object} jQuery jqXHR object (Set).
         * @example
         *      user_name = Inffuse.user.name()
         *      console.log("User name=%s", user_name);
         * @example Wait for response
         *      Inffuse.user.name('New User Name')
         *              .done(function(){
         *                      // Success!
         *              })
         *              .fail(function(){
         *                      // Handle set name failure
         *              });
         */
        // TODO: consolidate with user.name()
        this.name = function(name)
        {
                if (typeof name == 'undefined')
                        return meta.name;
                
                meta['name'] = name;
                
                var params = {
                        user: self.id(),
                        name: name
                };
                        
                return Inffuse.requestAPI('users/update',params,'POST')
        }
        
        /**
         * Open upgrade/billing page.
         * @method Inffuse.user.upgrade
         */
        // TODO: What is the return value of the overide function ???
        this.upgrade = function(plan)
        {
                var billing_service = '{{billing_service}}';
                switch (billing_service) {
                        case 'stripe':
                                Inffuse.services.stripe.checkout({
                                        type:'subscription',
                                        amount: 15,
                                        plan: plan,
                                        description: 'Subscribe to ' + plan + ' plan'
                                });
                                break;
                }
                
                Inffuse.ui.alert("Inffuse error :: upgrade() is not supported on " + Inffuse.platform + " platform");
        }
        
        /*------------------------------------------*/
        
        /**
         * Get the current access_token to be used for authorizing API calls.
         * @method Inffuse.user.accessToken
         * @returns {string}
         */
        this.accessToken = function()
        {
                return meta ? meta.access_token : null;
        }

        /*------------------------------------------*/

        /**
         * Helper function to check the plan of the user
         * @method Inffuse.user.is
         * @param {string} plan Plan to test against.
         * @returns {boolean} Returns true if the plan of the user equals the one passed.
         * @example
         *      if (Inffuse.user.is('premium'))
         *              console.log("Premium user...");
         */
        this.is = function(plan)
        {
                return (plan == this.plan());
        }

        /**
         * Helper function to check if the user on the free plan.
         * @method Inffuse.user.free
         * @returns {boolean} Returns true if the user is on the free plan.
         * @example
         *      if (Inffuse.user.free())
         *              console.log("Free user...");
         */
        this.free = function()
        {
                var plan = this.plan();
                return (!plan || plan == 'free');
        }

        /*------------------------------------------*/

        /**
         * Get the dataStore for the default scope or the <scope_override> for the user
         * @method Inffuse.project.getDataStore
         * @param {string} scope_override if given, overrides the default scope. legal values are "private" or "public".
         */
        this.getDataStore = function(scope_override)
        {
                if (!self.dataStore) {
                        self.dataStore = new Inffuse.DataStore(Inffuse, 'users', self.id(), 'private');
                        self.dataStore.populate(data);
                }
                
                return self.dataStore;
        }       
                
        /*------------------------------------------*/
        
        /**
         * Helper function to display a direct link to the users account in Inffuse dashboard.
         * The link will be printed using console.log().
         * @method Inffuse.user.manage
         */
        this.manage = function() {
                var host = Inffuse.server.indexOf("local") == -1 ? "dashboard.inffuse.com" : "dev.inffuse.local:28099";
                var url = [
                        "http:/",
                        host,
                        "app:"+Inffuse.app.id(),
                        "users",
                        "user:"+self.id()
                ].join('/');

                console.log('To manage the user go to: ',url);
        }

        /*------------------------------------------*/  
};
/**
* @namespace
* @memberof Inffuse
*/

InffuseSDK_01.prototype.Services = function(inffuse_instance,services)
{
        var Inffuse = inffuse_instance;
        var self = this;

        function addServiceMethod(obj,service_name,method_name,func) {
                var path = [service_name,method_name].join('.');
                var parts = path.split('.');

                for (var i=0; i < parts.length-1; i++)
                {
                        var part = parts[i];

                        if (typeof obj[part] == 'undefined')
                                obj[part] = {};

                        obj = obj[part];
                }

                var last = parts.pop();
                obj[last] = func;
        }

        /* creating all supported services */
        for (var iService = 0; iService < services.length; iService++)
        {
                var service = services[iService];

                for (var iMethod = 0; iMethod < service.methods.length; iMethod++)
                {
                        var method = service.methods[iMethod];

                        (function(service,method) {
                                var method_func = function(){
                                
                                        var args = {};
                                        for (var i = 0; i < method.args.length; i++) {
                                                var name = method.args[i];
                                                var value = arguments[i];

                                                args[name] = value;
                                        }

                                        var _service = service.name.replace(/\./g,'/');
                                        var _method = method.name.replace(/\./g,'/');
                                        
                                        var _params = {
                                                method: [_service,method.name].join('.'), // for possible error message
                                                path: ['services',_service,_method].join('/'),
                                                type: method.type // default:"GET"
                                        }

                                        _params['args'] = {};
                                        for (var name in args) {
                                                var value = args[name];
                                                if (typeof value == 'function')
                                                        continue;

                                                if (typeof value == 'object')
                                                        value = JSON.stringify(value)

                                                _params['args'][name] = value;
                                        }

                                        _params.args['platform'] = Inffuse.platform;
                                        _params.args['app'] = Inffuse.app.id();

                                        if (Inffuse.user) {
                                                _params.args['user'] = Inffuse.user.id();
                                                _params.args['site'] = Inffuse.site ? Inffuse.site.id() : null;
                                                _params.args['project'] = Inffuse.project ? Inffuse.project.id() : null;
                                        }
                                        
                                        if (method.args.indexOf("callback") != -1)
                                                _params['callback'] = args.callback;
                                        
                                        return Inffuse.services[method.action || 'fetch'](_params);
                                };

                                addServiceMethod(self, service.name, method.name, method_func);

                        })(service,method);
                }
        }

        this.fetch = function(params)
        {
                return Inffuse.requestAPI(params.path,params.args,params.type)
                        .fail(function(data){
                                if (data.responseJSON && data.responseJSON.error)
                                        Inffuse.error(['Request to',params.method,'failed','('+data.responseJSON.error+')'].join(' '));
                        });
        }

        this.openWindow = function(params)
        {
                function onMessage(event)
                {
                        var data = event.data;
                        if (data.app != 'inffuse')
                                return;

                        if (data.type == 'connected')
                        {
                                //console.log("received message",event);
                                window.removeEventListener("message",onMessage);

                                params.callback(data.params);
                        }
                }

                // session id will be used for polling key on IE
                var session_id = Math.floor(Math.random()*100000000);
                params.args['session'] = session_id;

                var url = [Inffuse.server,'api',Inffuse.apiVersion,params.path].join('/');
                url += '?'+Inffuse.utils.buildQuery(params.args);

                var popup = window.open(url,"Inffuse","height=600,width=600");

                var IEbrowser = "ActiveXObject" in window;
                if (!IEbrowser)
                        window.addEventListener('message', onMessage, false);
                else {
                        var interval = setInterval(function(){
                                Inffuse.requestAPI('async/get',{session: session_id},'GET')
                                        .then(function(response){
                                                if (!response.result)
                                                        return;

                                                clearInterval(interval);
                                                params.callback(response.data);
                                        })
                        },1000);
                }
        }
};
InffuseSDK_01.prototype.StripeClass = function(inffuse_instance) 
{
        var Inffuse = inffuse_instance;
        
        this.checkout = function(params) {
                
                var publishable_key = params.publishable_key;

                function checkout() {
                        var handler = StripeCheckout.configure({
                                key: publishable_key,
                                image: params.image,
                                token: function(token) {
                                        // console.log(token);
                                        switch (params.type) {
                                                case 'charge':
                                                        Inffuse.services.stripe.charge(token.id,params.amount,params.test);
                                                        break;

                                                case 'subscription':
                                                        Inffuse.services.stripe.subscribe(token.id,params.plan,params.test);
                                                        break;
                                        }
                                }
                        });

                        var period = params.period || 'month';
                        handler.open({
                                name: params.name,
                                description: params.description,
                                amount: params.amount*100,
                                panelLabel: 'Subscribe ({{amount}}/'+period+')',
                                allowRememberMe: false
                        });
                }


                Inffuse.loadScript("https://checkout.stripe.com/checkout.js",checkout);
        }
};
InffuseSDK_01.prototype.FastspringClass = function(inffuse_instance) 
{
        var Inffuse = inffuse_instance;
        var self = this;
        
        this.renderUrl = function(url,params){
                params = params || {};
                params.platform = Inffuse.platform;
                params.user = Inffuse.user.id();

                if (Inffuse.site)
                        params.site = Inffuse.site.id();

                var info = encodeURI(JSON.stringify(params));
                return url+"?referrer="+info;
        }

        this.open = function(product_url) {
                window.open(self.renderUrl(product_url));
        }
};
InffuseSDK_01.prototype.WixClass = function(inffuse_instance)
{
        var Inffuse = inffuse_instance;
        var self = this;
        
        self.siteInfo = null;
        
        this.init = function()
        {
                if (typeof Wix == 'undefined')
                        return Inffuse.ui.alert("Inffuse error :: Wix SDK not loaded.");

                // overrides
                Inffuse.user.upgrade = self.upgrade;
                Inffuse.broadcast = self.broadcast;
                Inffuse.site.meta = self.site_meta;
                Inffuse.site.pages = self.site_pages;
                Inffuse.site.currentPage = self.site_currentPage;
                Inffuse.utils.openPopup = self.openPopup;
                Inffuse.utils.closePopup = self.closePopup;

                (Wix.getSiteInfo || Wix.Worker.getSiteInfo)( function(info) {
                        this.siteInfo = info;
                });

                var addEventListener = (Wix.addEventListener || Wix.Worker.addEventListener);
                addEventListener(Wix.Events.SITE_PUBLISHED, function() {
                        Inffuse.project.publish();
                });
                
                addEventListener(Wix.Events.COMPONENT_DELETED, function() {
                        // sent when closing a popup as well (?!)
                        if (Inffuse.viewMode() != 'editor')
                                return;

                        Inffuse.project.remove();
                });

                addEventListener(Wix.Events.STYLE_PARAMS_CHANGE, function(params, typeOfCall) {
                        Inffuse.trigger("style-changed");
                });

                addEventListener(Wix.Events.EDIT_MODE_CHANGE, function(params, typeOfCall) {
                        Inffuse._viewMode = params.editMode;
                        Inffuse.trigger("view-mode-changed",Inffuse._viewMode);
                });

                addEventListener(Wix.Events.SETTINGS_UPDATED, function(message) {
                        Inffuse.receiveMessage(message);
                });

                addEventListener(Wix.Events.PAGE_NAVIGATION, function(data) {
                        Inffuse.trigger("route-changed",{page: data.toPage});
                });

                // bug work-around - wix passes viewMode=preview even though we're in editor
                if (Wix.Utils && Wix.Utils.getViewMode)
                        Inffuse._viewMode = Wix.Utils.getViewMode();
                
                /*
                function onSizeChanged()
                {
                        Wix.setHeight($('body').outerHeight());
                }

                $(window).resize(onSizeChanged);
                $(window).bind("load",onSizeChanged);
                */
        }

        this.demoMode = function() {
                return false;
        }


        /*----------------------------------------------*/
        /* Overrides                                                                                            */
        /*----------------------------------------------*/

        this.upgrade = function()
        {
                Wix.Settings.openBillingPage();
        }

        this.broadcast = function(type,params,includeSelf)
        {
                var compId = Inffuse.project ? Inffuse.project.key() : undefined;
                Wix.Settings.triggerSettingsUpdatedEvent({type: type, params: params}, compId);

                if (includeSelf)
                        Inffuse.trigger(type,params);
        }

        this.site_meta = function(callback)
        {
                (Wix.getSiteInfo || Wix.Worker.getSiteInfo)(function(siteInfo) {
                        callback({
                                title: siteInfo.siteTitle,
                                pageTitle: siteInfo.pageTitle,
                                description: siteInfo.siteDescription,
                                keywords: siteInfo.siteKeywords,
                                referrer: siteInfo.referrer,
                                url: siteInfo.url,
                                host: siteInfo.baseUrl
                        });
                });
        }

        this.site_pages = function(callback)
        {
                (Wix.getSitePages || Wix.Worker.getSitePages)(function(sitePages) {
                        callback(sitePages);
                });
        }

        this.site_currentPage = function(callback) 
        {
                Wix.getCurrentPageId(function(page_id){
                        callback(page_id);
                });
        }
        
        this.openPopup = function(params)
        {
                Wix.openModal(
                        params.url,
                        params.width,
                        params.height,
                        {origin:Wix.WindowOrigin.FIXED},
                        params.onClose,
                        Wix.Theme.BARE
                );
        }

        this.closePopup = function()
        {
                Wix.closeWindow();
        }

        this.openModal = function(params)
        {
        }

        Inffuse.ui.openModal = function(params,callback)
        {
                Wix.Settings.openModal(params.src, params.width, params.height, params.title, callback);
        }

        Inffuse.ui.closeModal = function(message)
        {
                Wix.Settings.closeWindow(message);
        }
}
InffuseSDK_01.prototype.WeeblyClass = function(inffuse_instance)
{
        var Inffuse = inffuse_instance;
        var self = this;

        this.init = function()
        {
                // overrides
                Inffuse.broadcast = self.broadcast;
        }

        self.broadcast = function(event,data,includeSelf)
        {
                if (!window.parent)
                        return;

                var msg = {
                        app: 'inffuse',
                        user: Inffuse.user ? Inffuse.user.key() : null,
                        // site: Inffuse.site ? Inffuse.site.key() : null, // needed
                        project: Inffuse.project ? Inffuse.project.key() : null,
                        type: event,
                        params: data
                }

                if (window.parent.postMessage)
                        window.parent.postMessage(msg, "*");

                // http://stackoverflow.com/questions/13581493/communication-between-two-iframe-children-using-postmessage
                for (var i=0; i < window.parent.frames.length; i++) {
                        var iframe = window.parent.frames[i];
                        if (iframe == window)
                                continue;
                        
                        iframe.postMessage(msg, "*");
                }

                if (includeSelf)
                        Inffuse.trigger(event,data);
        }

        Inffuse.ui.openModal = function(params,callback)
        {
                Inffuse.broadcast('open-modal',params,true);
        }

        Inffuse.ui.closeModal = function(message)
        {
                Inffuse.broadcast('close-modal',undefined,true);
        }
}
InffuseSDK_01.prototype.ShopifyClass = function(inffuse_instance)
{
        var Inffuse = inffuse_instance;
        var self = this;
        var metadata;
        
        self.init = function(_metadata)
        {
                metadata = _metadata;
                self.preview_url = metadata.widget_url;

                if (Inffuse.editing)
                {
                        Inffuse.loadScript('https://cdn.shopify.com/s/assets/external/app.js',function(){
                                ShopifyApp.init({
                                        apiKey: metadata.api_key,
                                        shopOrigin: 'https://'+ metadata.shop,
                                        forceRedirect: metadata.force_redirect
                                });

                                var logo_url = metadata.logo_url;
                                if (!logo_url)
                                        logo_url = [document.location.protocol,'//',document.location.host,'/img/logo_86.png'].join('');

                                var buttons = {};
                                buttons['primary'] = {
                                        label: 'Publish', 
                                        message: 'publish', 
                                        callback: function(){
                                                setTimeout(ShopifyApp.Bar.loadingOff,200);

                                                Inffuse.project.publish();
                                                ShopifyApp.flashNotice("Your changes were published.")
                                        }
                                };

                                buttons['secondary'] = [];
                                
                                if (metadata.widget_url) {
                                        buttons['secondary'].push({
                                                label: 'Preview', 
                                                message: 'preview', 
                                                callback: Inffuse.shopify.preview
                                        });
                                }
                                
                                var has_premium = metadata.has_premium;
                                if (has_premium && Inffuse.user.free()) {
                                        buttons['secondary'].push({
                                                label: 'Upgrade', 
                                                message: 'upgrade',
                                                callback: function(){
                                                        setTimeout(ShopifyApp.Bar.loadingOff,200);

                                                        Inffuse.trigger('upgrade');
                                                        //doSomeCustomAction();
                                                }
                                        });
                                }
                                                
                                // ShopifyApp.ready is not called if the shopify script is loaded asynchroniously
                                // (the "ready" message is sent before the message handled is set up by the script,
                                // and is therefore missed)
                                // Since inffuse is loaded asynchroniously itself, this shouldn't be a problem - 
                                // Shopfiy API should be loaded by now.
                                ShopifyApp.Bar.loadingOff();
                                ShopifyApp.Bar.initialize({
                                        icon: metadata.logo_url,
                                        title: 'Settings',
                                        buttons: buttons
                                });
                        });
                }
        }

        self.setPreviewUrl = function(url)
        {
                self.preview_url = url;
        }

        self.preview = function()
        {
                setTimeout(ShopifyApp.Bar.loadingOff,200);

                if (!self.preview_url)
                        return Inffuse.ui.alert("Inffuse error :: preview URL is not set");

                Inffuse.ui.openModal({
                        src: self.preview_url,
                        title: 'Preview',
                        width: 'small',
                        height: 350,
                        buttons: {
                                primary: { 
                                        label: "Close",
                                        callback: Inffuse.ui.closeModal
                                }
                        }
                });
        }

        /*----------------------------------------------*/
        /* Overrides                                                                                            */
        /*----------------------------------------------*/

        Inffuse.ui.alert = function(str)
        {
                ShopifyApp.flashNotice(str);
        }

        Inffuse.ui.openModal = function(params,callback)
        {
                ShopifyApp.Modal.open(params, callback);
        }

        Inffuse.ui.closeModal = function()
        {
                ShopifyApp.Modal.close();
        }

        Inffuse.user.upgrade = function(plan,amount,test)
        {
                Inffuse.services.shopify.createCharge(metadata.shop,plan,amount,test)
                        .then(function(response) {
                                window.top.location.href = response.confirmation_url;
                        });
        }
}


export default InffuseSDK_01;