import React from 'react';
import {useContext} from "react";
import {Redirect} from "react-router-dom";

import UserDataContext from './context.js';

/*---------------------------------------------*/

export default function withLogin(Component) {
	return (props) => {
		const context = useContext(UserDataContext);
		if (!context.loggedIn()) {
			const target = {
				pathname: "/user/login",
				search: "?next="+document.location.pathname
			};

			return <Redirect to={target} />;
		}
	
		return Component(props);
	}
}

/*---------------------------------------------*/