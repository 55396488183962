import React from 'react';

import {Checkbox,Divider,Grid,Heading,Icon,Text} from 'designSystem';
import {
	Switch,
	NavLink,
	Route
} from "react-router-dom";

import Loader from 'admin-kit-local/components/loader';

import BaseView from 'pages/base';

/*---------------------------------------------*/

class DashboardTemplate extends BaseView {
	constructor() {
		super();

		this.state = {};
	}

	componentDidMount() {
		this.context.onChange(() => this.forceUpdate());
	}

	render() {
		if (this.props.loading) {
			return <Loader />;
		}

		let styles = {
			header: {
				display: 'grid',
				gridTemplateColumns: '1fr auto',
				alignItems: 'center'
			},
			icon: {
				width: 20,
			}
		}

		let developmentTools = false && (process.env.NODE_ENV === 'development') && [
			<Checkbox label='Show grid' onChange={value => this.setState({showGrid: value})} />,
			<Checkbox label='Fluid grid' onChange={value => this.setState({fluidGrid: value})} />
		]
		
		return (
			<Grid className="dashboard" showGrid={this.state.showGrid} fluid nomargin narrow>
				<div className="card menu" grid-start={1} grid-end={4}>
					<div style={{height: 'var(--space-stack-l)'}} />
					{this.props.menuTitle ? <Heading variant='heading6' style={{marginLeft: 8}}>{this.props.menuTitle}</Heading> : null}
					<div style={{height: 'var(--space-stack-s)'}} />
					<ul> 
						{
							this.props.pages.filter(page => !!page.menu).sort((a,b) => a.menu.order - b.menu.order).map((page,index) => {
								const ContentComponent = page.menu.content;
								const content = ContentComponent ? <ContentComponent {...this.props} /> : [
									<Icon className='icon' icon={page.menu.icon} size={16} style={styles.icon} key='icon' />,
									<Text size='small' key='text'>{page.menu.label}</Text>
								];

								const separator = !page.menu.separator ? null : [
									<div style={{height: 'var(--space-stack-l)'}} key={`${page.slug}-div1`} />,
									<Divider style={{height: 0}} key={`${page.slug}-divider`} />,
									<div style={{height: 'var(--space-stack-m)'}} key={`${page.slug}-div2`} />,
								]

								return [
									<li key={page.slug}>
										<NavLink activeClassName='selected' to={`${this.props.basePath}/${page.slug}`} isActive={page.menu && page.menu.isActive} >
											{content}
										</NavLink>
									</li>,
									separator
								]
							})
						}
					</ul>

					<div style={{height: 'var(--space-stack-l)'}} />
					<Divider style={{height: 0}} />

					<div style={{position: 'absolute', bottom: 40, padding: 8}}>
						<NavLink to='/'>
							<Icon size={16} icon='arrowLeft' style={{marginTop: 4, marginRight: 8}} />
							<Text size='small'>Back to dashboard</Text>
						</NavLink>
					</div>
				</div>

				<div className="card content" grid-start={4}>
					<Switch>
						{
							this.props.pages.map(page => {
								let ContentClass = page.content;
								return (
									<Route path={`${this.props.basePath}/${page.match || page.slug}`} exact={true} key={page.slug}>
										<ContentClass {...this.props} />
									</Route>
								)
							})
						}
					</Switch>
				</div>
				{developmentTools}
			</Grid>
		)
	}
}

/*---------------------------------------------*/

export default DashboardTemplate;
