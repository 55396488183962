import React from 'react';
import {useState,useContext} from "react";

import {Button,Grid,Heading,Input,Form,Stack} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';
import withLogin from 'admin-kit-local/tools/withLogin';

import 'index.scss';

/*---------------------------------------------*/

function ContactDetailsPage(props) {
	const context = useContext(UserDataContext);
	const [dirty,setDirty] = useState(false);

	return (
		<Grid fluid>
			<Heading variant='heading3'>Contact Details</Heading>
			<div style={{height: 'var(--space-stack-m)'}} />
			<Form onSubmit={onDetailsSubmit} onChange={onChange} key='details-form'>
				<Stack spacing='normal' style={{textAlign: 'left', maxWidth: 300}}>
					<Input value={context.inffuse.user.name()} label='Name' type='text' name='name' />
					<Input value={context.inffuse.user.email()} label='Email' type='email' name='email' />
					<Button loading={Form.loading} disabled={!dirty}>Save</Button>
				</Stack>
			</Form>
		</Grid>
	)

	/*------------------------------------------*/

	function onDetailsSubmit(values) {
		return new Promise((resolve, _reject) => {
			context.inffuse.user.update(values)
				.then(() => setDirty(false))
				.always(resolve);
		});
	}

	function onChange() {
		setDirty(true);
	}
}

/*---------------------------------------------*/

export default withLogin(ContactDetailsPage);
