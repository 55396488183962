import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';

import {Button,Checkbox,Heading,Divider,Input,Form,Link,Stack,Text} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';
import CardTemplate from '../card.js';

function SignupView(props) {
	const context = useContext(UserDataContext);
	if (context.loggedIn()) {
		context.redirect(props.history,'/');
	}

	return (
		<CardTemplate>
			<div className='card'>
				<Heading key='heading' variant='heading5'>Sign up</Heading>
				<br />
				<Form grid-start={1} grid-end={6} onSubmit={onSubmit} key='form'>
					<Stack spacing='comfortable' style={{textAlign: 'left'}}>
						<Input label='Full Name' type='text' name='name' onChange={onInputChange} />
						<Input label='Email' type='text' name='email' onChange={onInputChange} />
						<Input label='Password' type='password' name='password' onChange={onInputChange} />
						<Checkbox label='Remember me' onChange={onInputChange} />
						<div>
							<Button variant="primary" fullwidth loading={Form.loading}>Create Account</Button>
							{/* <Divider label='or' style={{marginTop: 16, marginBottom: 16}} />
							<Button variant="text" fullwidth style={STYLES.googleButton}><img src='/img/google.svg' style={STYLES.googleButtonIcon} alt='google-logo' />Connect with Google</Button> */}
						</div>
						<div style={{textAlign: 'center'}}>
							<Link href='/user/forgot-password'>Forgot password?</Link>
						</div>
					</Stack>
				</Form>
			</div>
			<div style={{marginTop: 8}}>
				<Text>Have an account already?</Text>
				&nbsp;
				<Link href='/user/login'>Login</Link>
			</div>
		</CardTemplate>
	)
	
	/*------------------------------------------*/

	function onInputChange() {
	}

	function onSubmit(data) {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const queryParams = Object.fromEntries(urlSearchParams.entries());
		
		let nextUrl = queryParams.next || '/';
		return context.signup(data.name,data.email,data.password).then(() => context.redirect(props.history,nextUrl));
	}
}

/*---------------------------------------------*/

const STYLES = {
	googleButton: {
		color: 'var(--color-deep-90)',
		boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)'
	},
	googleButtonIcon: {
		marginTop: 2,
		marginRight: 10,
		verticalAlign: 'top'
	}
}

/*---------------------------------------------*/


export default withRouter(SignupView);
