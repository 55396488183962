import React, {useState,useContext} from 'react';
import {withRouter} from 'react-router-dom';

import {Button,Icon,Heading,Divider,Form,Input,Link,Stack,Text} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';
import CardTemplate from '../card.js';


function ForgotPasswordView(props) {
	const context = useContext(UserDataContext);
	const [done,setDone] = useState(false);
	const [email,setEmail] = useState(null);

	let content = done ?
		<div>
			<Heading key='heading' variant='heading5'>Reset password</Heading>
			<Stack.Spacer height='xl' />
			<Icon icon='CheckmarkOutline' size={56} color='var(--color-primary-80)' />
			<Stack.Spacer height='xl' />
			<Text>
				We've sent you an email to<br/>
				<b>{email || 'email@company.com'}</b>
			</Text>
			<Stack.Spacer height='l' />
			<Text color='--neutral-70'>
				Check your email for instructions<br/>
				to reset your password.
			</Text>
		</div> : 
		<div>
			<Heading key='heading' variant='heading5'>Reset password</Heading>
			<Stack.Spacer height='xl' />
			<Text style={{textAlign: 'left', display: 'block'}} color='--neutral-70'>Enter your email address and we'll send you a link to reset your password.</Text>
			<Stack.Spacer height='xl' />
			<Form onSubmit={onSubmit} key='form' style={{textAlign: 'left'}}>
				<Input label='Email' type='text' name='email' />
				<Stack.Spacer height='xxl' />
				<Stack.Spacer height='s' />
				<Button variant="primary" fullwidth loading={Form.loading}>Continue</Button>
			</Form>
			<Stack.Spacer height='xl' />
			<Divider />
			<Stack.Spacer height='m' />
			<Text>Go back to <Link href='/user/login'>login page</Link></Text>
		</div>;

	return (
		<CardTemplate>
			<div className='card'>
				{content}
			</div>
		</CardTemplate>
	)

	/*------------------------------------------*/
	
	function onSubmit(data) {
		setEmail(data.email);
		return context.sendPasswordReset(data.email).then(() => setDone(true));
	}
}

export default withRouter(ForgotPasswordView);
