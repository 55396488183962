import React from 'react';
import {useContext,useEffect,useState} from 'react';

import {
	withRouter
} from "react-router-dom";

import UserDataContext from 'admin-kit-local/tools/context';
import DashboardTemplate from 'admin-kit-local/templates/dashboard';
import withLogin from 'admin-kit-local/tools/withLogin';

import PageSettings from './page.js';
import PopupSettings from './settings.js';
import TemplatesPage from './templates.js';
import TextPage from './text.js';
import InstallPage from './install.js';
import PreviewPane from './preview.js';

import {Button,Heading,Text,Toaster} from 'designSystem';

/*---------------------------------------------*/

// inffuse app
// 1389642177718478
// 20878656da2a57e0aa9d5461c9da8a3a

//

/*---------------------------------------------*/

let PAGES = [
	{
		slug: '',
		content: PageSettings,
		menu: {
			icon: "blog",
			label: 'Page',
			separator: true,
			content: (props) => {
				const title = props.project.name() || 'Untitled';
				const subtitle = props.site.domain || 'Untitled';
				const textStyle = {
					fontWeight: 'normal',
					overflow: 'hidden',
					maxWidth: '100%',
					textOverflow: 'ellipsis',
				}

				return (
					<div style={{fontSize: 0}}>
						<div style={{display: 'grid', gridTemplateColumns: 'auto min-content', alignItems: 'center'}}>
							<Heading variant='heading6'>{title}</Heading>
						</div>
						<Text color='--neutral-70' style={textStyle}>{subtitle}</Text>
					</div>
				)
			},
			isActive: () => {
				let parts = document.location.pathname.replace(/\/$/,'').split('/');
				return parts.length === 3;
			}
		}
	},
	{
		slug: 'settings',
		content: PopupSettings,
		menu: {
			icon: "settings",
			label: 'Settings',
		}
	},
	{
		slug: 'templates',
		content: TemplatesPage,
		menu: {
			icon: 'template',
			label: 'Templates'
		}
	},
	{
		slug: 'content',
		content: TextPage,
		menu: {
			icon: 'document',
			label: 'Content'
		}
	},
	{
		slug: 'install',
		content: InstallPage,
		menu: {
			icon: 'code',
			label: 'Install'
		}
	}
]

/*---------------------------------------------*/

function ProjectIndex(props) {
	const [previewVisible,setPreviewVisible] = useState(true);
	const [site,setSite] = useState(null);
	const [project,setProject] = useState(null);

	const context = useContext(UserDataContext);

	useEffect(() => {
		let id = props.match.params.id;
		
		context.loadProject(id, (project) => {
			let site = context.sites().find((site) => site.id === project.siteID());
			setSite(site);
			setProject(project);
		});
	},[]);

	useEffect(() => {
		if (site && !site.plan && !site.plan_override) {
			const upgradePath = `/user/account/billing/pricing?site=${site.id}`;
			context.setCustomHeaderContent(<Button variant='primary' href={upgradePath} size='small' style={{verticalAlign: 'middle'}}>Upgrade</Button>);
		}

		return () => {
			context.setCustomHeaderContent(null);
		}
	},[site]);

	let previewProps = {
		project: project,
		inffuse: context.inffuse,
		accessToken: context.inffuse.user.accessToken(),
		onToggle: (value) => setPreviewVisible(value),
		visible: previewVisible,
	}

	const previewPane = <PreviewPane {...previewProps} />;
	
	return (
		<DashboardTemplate
			loading={!project}
			basePath={project && `/projects/${project.id()}`}
			site={site}
			project={project}
			pages={PAGES}
			previewComponent={previewPane}
			onPublish={publishChanges}
		/>
	)

	function publishChanges() {
		return new Promise((resolve, reject) => {
			project.publish()
				.done(() => {
					context.isDirty(false);
					context.track('Changes Published');
					
					Toaster.add({title: 'Success', content: 'Your changes were published successfully', type: 'success'});
					resolve();
				})
				.fail((result) => {
					reject(result.responseJSON?.error);
				});
		});
	}
}

/*---------------------------------------------*/

export default withRouter(withLogin(ProjectIndex));
