import React from 'react';
import {useContext} from "react";
import {withRouter} from 'react-router-dom';

import UserDataContext from 'admin-kit-local/tools/context';

import {Button,Form,Grid,Heading,Input,Pretitle,RangeSlider,Select,Stack,Text} from 'designSystem';
import InffuseForm from 'admin-kit-local/tools/inffuseform';


function PopupSettings(props) {
	const context = useContext(UserDataContext);
	
	return (
		<Grid fluid nomargin>
			<div style={{display: 'grid', gridTemplateColumns: 'auto min-content'}}>
				<div>
					<Heading variant='heading3'>Settings</Heading>
					<div style={{height: 'var(--space-stack-m)'}} />
					<Text bold>Customize when and where should the popup be displayed</Text>
					<div style={{height: 'var(--space-stack-l)'}} />

					<InffuseForm project={props.project} settingsKey='settings'>
						<Stack spacing="comfortable" style={{maxWidth: 400}}>
							<Select path="frequency" label='When should the popup be displayed?'>
								<Select.Option value='always' label='Every visit' />
								<Select.Option value='once' label='First visit only' />
								<Select.Option value='weekly' label='Once a week' />
							</Select>
							
							<RangeSlider path="delay" label='Delay before showing (sec)' valueType='number' min={0} max={300} units='sec' />

							<div>
								<div style={{height: 'var(--space-stack-l)'}} />
								<Pretitle color='--primary-900'>Page selection</Pretitle>
								<div style={{height: 'var(--space-stack-l)'}} />
								<Input path="whitelist" placeholder="/about" 
									label="Limit the popup to specific page(s)" 
									message="Enter the path of the page where the popup should appear. Leave empty to display on all pages. Regex is okay." />

								{/* <MultiInput path="paths" label="Select which pages should the popup be displayed on" /> */}
							</div>
						</Stack>
					</InffuseForm>
				</div>
				<Form silent onSubmit={props.onPublish} style={{textAlign: 'right'}}>
					<Button variant='primary' loading={Form.loading} disabled={!context.isDirty()} >Publish</Button>
				</Form>
			</div>
		</Grid>
	)
}

/*---------------------------------------------*/

export default withRouter(PopupSettings);
