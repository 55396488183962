import React from 'react';
import {useState,useContext} from "react";

import {Button,Heading,Form,Icon,Input,Stack,Text} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';

import 'index.scss';

/*---------------------------------------------*/

function ContactUsPage(props) {
	const context = useContext(UserDataContext);
	const [data,setData] = useState(false);
	const [sent,setSent] = useState(false);
	const [_error,setError] = useState(false);

	if (sent) {
		return (
			<div style={STYLES.confirmation.container}>
				<Heading variant='heading3'>Thank you</Heading>
				<Stack.Spacer height='l' />
				<Icon icon='checkmarkOutline' color='--success-30' size={64} />
				<Stack.Spacer height='l' />
				<Text>
					We got your message <br/> 
					and will get back to you <br/>
					as soon as possible.
				</Text>
			</div>
		)
	}

	const isFormValid = !!data.name && !!data.email && !!data.message;

	return (
		<Form onSubmit={onSubmit} onChange={onChange} key='form' style={{width: 288, paddingTop: 48, margin: 'auto'}}>
			<Heading variant='heading3' style={{textAlign: 'center'}}>Contact Us</Heading>
			<Stack.Spacer height='m' />
			<Text style={{width: 'calc(100% + 200px)', marginLeft: -100, textAlign: 'center'}}>
				Have questions? have any feedback? <br/>
				We're happy to hear from you! <br/>
				Please complete the form and we will be in touch shortly.
			</Text>
			<Stack.Spacer height='m' />
			<Stack.Spacer height='s' />
			<Stack spacing='normal' style={{textAlign: 'left'}}>
				<Input label='Full Name' type='text' name='name' />
				<Input label='Email' type='text' name='email' />
				<Input label='Subject' type='text' name='subject' />
				<Input label='How can we help you?' type='textarea' name='message' />
				
				<Button variant="primary" fullwidth spacing='spaced' disabled={!isFormValid} loading={Form.loading}>Send</Button>
			</Stack>
		</Form>
	)

	/*------------------------------------------*/
	
	function onChange(data) {
		setData(data);
	}

	function onSubmit(data) {
		return new Promise((resolve, reject) => {
			context.inffuse.services.zendesk.submit(data.name,data.email,{
				subject: data.subject,
				message: data.message
			})
			.success(() => {
				context.track('Contact Form Submitted',data);
				setSent(true);
				resolve();
			})
			.error(() => {
				setError(true);
				reject();
			});
		});
	}
}

/*---------------------------------------------*/

const STYLES = {
	confirmation: {
		container: {
			margin: 'auto',
			marginTop: 128,
			paddingTop: 40,
			paddingBottom: 56,
			width: 448,
			background: 'var(--color-neutral-10)',
			boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)',
			borderRadius: 8,
		}
	}
}

/*---------------------------------------------*/

export default ContactUsPage;
