import React from 'react';
import {useState,useContext} from "react";

import {Button,Grid,Heading,RadioButton,RadioButtonGroup} from 'designSystem';
import UserDataContext from 'admin-kit-local/tools/context';
import withLogin from 'admin-kit-local/tools/withLogin';

import 'index.scss';

/*---------------------------------------------*/

function PricingPage(_props) {
	const context = useContext(UserDataContext);
	const [period,setPeriod] = useState('monthly');

	const queryParams = new URLSearchParams(window.location.search);
	const siteId = queryParams.get('site');

	const site = context.sites().find(site => site.id === siteId);

	let checkoutLink = {
		monthly: 'https://sites.fastspring.com/inffuse/instant/likepopupmonthly',
		yearly: 'https://sites.fastspring.com/inffuse/instant/likepopupyearly',
	}[period];

	checkoutLink = context.inffuse.fastspring.renderUrl(checkoutLink, {site:site.id});

	return (
		<Grid fluid>
			<Heading variant='heading3'>Pricing</Heading>
			<Heading variant='heading6'>{site.domain}</Heading>

			<div style={{height: 'var(--space-stack-m)'}} />
			<Heading variant='heading5'>Premium Features</Heading>
			<ul>
				<li>Premium templates</li>
				<li>Unlimited views</li>
				<li>No LikePopup branding</li>
				<li>Premium Support</li>
			</ul>
			<br/>
			<div>
				<RadioButtonGroup inline value={period} onChange={onPeriodChange}>
					<RadioButton value="monthly">Monthly ($2.99/month)</RadioButton>
					<RadioButton value="yearly">Yearly ($20/year - save 45%!)</RadioButton>
				</RadioButtonGroup>
			</div>
			<br/>
			<div>
				<Button variant="primary" href={checkoutLink} onClick={onUpgradeClick} target="_blank">Upgrade Site</Button>
			</div>
		</Grid>
	)

	function onPeriodChange(value) {
		setPeriod(value);

		context.track('Pricing Period Changed',{period: value});
	}

	function onUpgradeClick() {
		context.track('Upgrade Button Clicked',{period})
	}
}

/*---------------------------------------------*/

export default withLogin(PricingPage);
