import './8.scss';

import React from 'react';

import LikeButton from '../likebutton.js';
import Image from '../image.js';

import {ReactComponent as CloseButton} from '../img/close8.svg';
import backgroundImage from '../img/template_8_background_medium.svg';

console.log("backgroundImage",backgroundImage);
const backgroundStyle = {
   backgroundImage: `url(${backgroundImage})`
};

class Template extends React.Component {
   render() {
      const settings = this.props.settings;
      const fbPage = this.props.fbPage;

      return (
         <div id="background-container" style={backgroundStyle}>
            <button id="close" onClick={this.props.onClose} aria-label="Close">
               <CloseButton />
            </button>
            <div id="content">
               <h2>{settings.title}</h2>
               <h3>{settings.subtitle}</h3>
            </div>
            <div id="footer">
               <div id="like-with-details">
                  <LikeButton pageId={fbPage.id} key={fbPage.id} />
                  <div className="details">{fbPage.prompt}</div>
               </div>
            </div>
         </div>
      );
   }
}

export default Template;
