import './4.scss';

import React from 'react';
import LikeButton from '../likebutton.js';
import Image from '../image.js';

import {ReactComponent as CloseButton} from '../img/close_fill_1.svg';
import UsersGallery from '../components/UsersGallery';

class Template extends React.Component {
	render() {
		const settings = this.props.settings;
		const fbPage = this.props.fbPage;

		return (
			<React.Fragment>
				<button id="close" onClick={this.props.onClose} aria-label="Close">
					<CloseButton alt="close" />
				</button>

				<table width="100%" className="header">
					<tbody>
						<tr>
							<td width="1%">
								<Image cdn={this.props.cdn} id="profile" src={fbPage.profile} alt="page profile" />
							</td>
							<td>
								<h2>{settings.page_name || fbPage.name}</h2>
								<LikeButton pageId={fbPage.id} key={fbPage.id} />
							</td>
						</tr>
					</tbody>
				</table>

				<hr />

				<div className="details">{fbPage.prompt}</div>

				<UsersGallery users={fbPage.users} show={settings.showAvatars} />

				<hr id="bottom-hr" />
			</React.Fragment>
		);
	}
}

export default Template;
