import React from 'react';
import {useState,useEffect,useContext} from 'react';

import UserDataContext from 'admin-kit-local/tools/context';

import {Toggle} from 'designSystem';
import {LikePopup} from 'likepopup-component';
import 'likepopup-component/dist/index.css'

/*---------------------------------------------*/

function PreviewPane(props) {
	const context = useContext(UserDataContext);

	const [previewVisible,setPreviewVisible] = useState(props.visible);
	const [premiumPreviewId,setPremiumPreviewId] = useState(null);
	const [_settingsRevision,setSettingsRevision] = useState(0);

	useEffect(() => {
		props.inffuse.on('data-changed',() => {
			setSettingsRevision(Math.random());
		});
		
		props.inffuse.on('template-preview',(id) => {
			setPremiumPreviewId(id);
			setSettingsRevision(Math.random());
		});
	},[props.inffuse]);
	

	let settings = props.project.get('settings') || context.defaultSettings;
	if (premiumPreviewId) {
		settings = JSON.parse(JSON.stringify(settings));
		settings.template = settings.template || {};
		settings.template.id = premiumPreviewId;
	}

	const popupProps = {
		id: props.project.id(),
		accessToken: props.inffuse.user.accessToken(),
		settings: settings,
		previewId: premiumPreviewId,
	}

	return (
		<>
			<Toggle 
				label='Preview' 
				size='slim'
				value={previewVisible} 
				style={{display: 'inline-block'}} 
				onChange={onToggle}
			/>

			<div style={{height: 'var(--space-stack-m)'}} />
			{previewVisible ? <div style={STYLES.preview}><LikePopup {...popupProps} /></div> : null}
		</>
	);


	function onToggle(value) {
		setPreviewVisible(value);
		props.onToggle && props.onToggle(value);

		context.track('Preview Toggled',{value})
	}
}

export default PreviewPane;

/*---------------------------------------------*/

const BG_UNCHECKED = 'var(--color-neutral-100)';
const BG_CHECKED = 'var(--color-neutral-300)';

const STYLES = {
	preview: {
		position: 'relative',
		width: 680,
		height: 500,
		background: 'var(--color-neutral-200)',
		display: 'grid',
		alignItems: 'center',
		justifyContent: 'center',
		
		// https://stackoverflow.com/a/35362074
		backgroundColor: BG_UNCHECKED,
		backgroundImage: `
			linear-gradient(45deg, ${BG_CHECKED} 25%, transparent 25%), 
			linear-gradient(-45deg, ${BG_CHECKED} 25%, transparent 25%), 
			linear-gradient(45deg, transparent 75%, ${BG_CHECKED} 75%), 
			linear-gradient(-45deg, transparent 75%, ${BG_CHECKED} 75%)
		`,

		// linear-gradient(45deg, #808080 25%, transparent 25%), 
		// linear-gradient(-45deg, #808080 25%, transparent 25%), 
		// linear-gradient(45deg, transparent 75%, #808080 75%), 
		// linear-gradient(-45deg, transparent 75%, #808080 75%);
	 
		// backgroundImage: 'linear-gradient(45deg, var(--color-neutral-300) 25%, transparent 25%), linear-gradient(-45deg, var(--color-neutral-300) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--color-neutral-300) 75%), linear-gradient(-45deg, transparent 75%, #var(--color-neutral-300) 75%)',

		backgroundSize: '20px 20px',
		backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
	}
}

/*---------------------------------------------*/