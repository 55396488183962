import React from 'react';

let FbButton = (props) => {
	return (
		<div className='fb-button' onClick={props.onClick}>
			<img className='fb-button__image' src='/img/fb.png' />
			<div className='fb-button__text'>
				Continue with Facebook
			</div>
		</div>
	)
}

export default FbButton;